/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiText, EuiStat, EuiSpacer } from '@elastic/eui'

import CuiElasticConsumptionUnits from '@modules/cui/formatters/CuiElasticConsumptionUnits'
import type { Items } from '@modules/billing-api/v1/types'

import UsageStat from '../UsageStat'
import messages from '../../messages'

import {
  getDeploymentCosts,
  getDeploymentsTotalUsage,
  getProjectCosts,
  getProjectsTotalUsage,
} from './utils'
import ServerlessProjects from './ServerlessProjects'
import DedicatedDeployments from './DedicatedDeployments'

interface Props {
  isLoading: boolean
  data?: Items
}

const UsageSummary = ({ isLoading, data }: Props) => {
  const deploymentCosts = useMemo(() => getDeploymentCosts(data?.products), [data?.products])
  const projectCosts = useMemo(() => getProjectCosts(data?.products), [data?.products])

  const renderFullyManagedProjects = () => {
    if (!projectCosts) {
      return null
    }

    return (
      <ServerlessProjects
        isLoading={isLoading}
        totalUsage={getProjectsTotalUsage(data?.products)}
        costs={projectCosts}
      />
    )
  }

  return (
    <Fragment>
      <EuiStat
        isLoading={isLoading}
        title={
          <UsageStat
            title={
              <CuiElasticConsumptionUnits
                value={data?.total_ecu || 0}
                unit='none'
                withSymbol={false}
              />
            }
            subText={<FormattedMessage {...messages.ecuUnit} />}
            data-test-id='usage-card.total'
          />
        }
        titleSize='l'
        description={
          <EuiText size='s'>
            <FormattedMessage {...messages.currentUsage} />
          </EuiText>
        }
        descriptionElement='div'
      />

      <EuiSpacer size='m' />

      {renderFullyManagedProjects()}

      <EuiSpacer size='m' />

      <DedicatedDeployments
        totalUsage={getDeploymentsTotalUsage(data?.products)}
        isLoading={isLoading}
        costs={deploymentCosts}
      />
    </Fragment>
  )
}

export default UsageSummary
