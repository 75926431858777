/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'

import { useEuiTheme } from '@elastic/eui'

import type { ReactElement } from 'react'

interface UsageStatProps {
  title: string | React.ReactNode
  subText?: React.ReactNode
  'data-test-id'?: string
}

function UsageStat(props: UsageStatProps): ReactElement {
  const theme = useEuiTheme()

  return (
    <span data-test-id={props['data-test-id']}>
      {props.title}
      {props.subText && (
        <span css={css({ color: theme.euiTheme.colors.textDisabled })}> {props.subText}</span>
      )}
    </span>
  )
}

export default UsageStat
