/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiTitle,
  EuiSpacer,
  EuiCallOut,
  EuiFlyout,
  EuiFlyoutHeader,
  EuiFlyoutBody,
  EuiFlyoutFooter,
  EuiFlexGroup,
  EuiButtonEmpty,
} from '@elastic/eui'

import { addToast } from '@modules/cui/Toasts'
import { CuiAlert } from '@modules/cui/Alert'
import type { Keystore } from '@modules/ui-types'

import { isApiKeyRightFormat } from '@/lib/apiKeys'
import DocLink from '@/components/DocLink'

import SpinButton from '../../SpinButton'

import type { AllProps as Props } from '.'

type State = {
  newApiKeySetting: string
  newApiKeyValue: string
  errorOnAdding: boolean
  showSuccess: boolean
}

const toastText = {
  secretCreationSuccess: {
    family: 'add-api-key',
    dataTestSubj: 'add-api-key-success',
    title: (
      <FormattedMessage id='api-key.add-key.success' defaultMessage='API Key successfully saved' />
    ),
    color: 'success',
  },
}

class AddApiKeyFlyout extends React.Component<Props, State> {
  state: State = {
    newApiKeySetting: ``,
    newApiKeyValue: ``,
    errorOnAdding: false,
    showSuccess: false,
  }

  render(): JSX.Element {
    const { createSecretRequest, closeFlyout } = this.props
    return (
      <EuiFlyout onClose={closeFlyout} maxWidth={600}>
        <EuiFlyoutHeader>
          <EuiTitle size='m'>
            <h2>
              <FormattedMessage id='add-api-key.title' defaultMessage='Add API key' />
            </h2>
          </EuiTitle>
        </EuiFlyoutHeader>
        <EuiFlyoutBody>
          <EuiFormRow
            label={
              <FormattedMessage
                id='select-trust-level.rcs-2-add-key.setting-title'
                defaultMessage='Remote cluster name'
              />
            }
            isInvalid={this.state.errorOnAdding}
            error={
              <FormattedMessage
                id='select-trust-level.rcs-2-add-key.error'
                defaultMessage='This value must be lowercase and contain only letters, numbers, dashes, and underscores.'
              />
            }
            helpText={
              <FormattedMessage
                id='select-trust-level.rcs-2-add-key.help-text-2'
                defaultMessage="A unique identifier for the remote cluster. Must match the {boldRemoteClusterName} in this deployment's {boldStackManagement} settings."
                values={{
                  boldRemoteClusterName: (
                    <strong>
                      <FormattedMessage
                        id='bold-remote-cluster-name'
                        defaultMessage='remote cluster name'
                      />
                    </strong>
                  ),
                  boldStackManagement: (
                    <strong>
                      <FormattedMessage
                        id='bold-stack-management'
                        defaultMessage='Stack Management -> Remote Clusters'
                      />
                    </strong>
                  ),
                }}
              />
            }
          >
            <EuiFieldText
              onChange={(e) => this.setState({ newApiKeySetting: e.target.value })}
              value={this.state.newApiKeySetting}
              data-test-id='security.add-api-key.name'
            />
          </EuiFormRow>

          <EuiFormRow
            label={
              <FormattedMessage
                id='select-trust-level.rcs-2-add-key.value-title'
                defaultMessage='Cross-cluster API key (encoded format)'
              />
            }
            helpText={
              <FormattedMessage
                id='generate-cross-cluster-api-key.value.help.text'
                defaultMessage='Generate a cross-cluster API key in the remote cluster you’re connecting to. {learnMore}'
                values={{
                  learnMore: (
                    <DocLink link='KibanaApiGenerationKey' showExternalLinkIcon={true}>
                      <FormattedMessage
                        id='api-key-generation.learn-more'
                        defaultMessage='Learn more'
                      />
                    </DocLink>
                  ),
                }}
              />
            }
          >
            <EuiFieldText
              onChange={(e) => this.setState({ newApiKeyValue: e.target.value })}
              value={this.state.newApiKeyValue}
              data-test-id='security.add-api-key.secret'
            />
          </EuiFormRow>

          {createSecretRequest.error && (
            <Fragment>
              <EuiSpacer />
              <CuiAlert type='error'>{createSecretRequest.error}</CuiAlert>
            </Fragment>
          )}

          {this.state.showSuccess && (
            <Fragment>
              <EuiSpacer />
              <EuiFlexItem>
                <EuiCallOut color='success'>
                  <FormattedMessage
                    id='add-api-key.added.success'
                    defaultMessage='API Key added successfully. A deployment restart is required for Elasticsearch to pick up the new keys.'
                  />
                </EuiCallOut>
              </EuiFlexItem>
            </Fragment>
          )}
        </EuiFlyoutBody>
        <EuiFlyoutFooter>
          <EuiFlexGroup justifyContent='spaceBetween'>
            <EuiFlexItem grow={false}>
              <EuiButtonEmpty iconType='cross' onClick={closeFlyout} flush='left'>
                <FormattedMessage id='keystore.flyout.close' defaultMessage='Close' />
              </EuiButtonEmpty>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <SpinButton
                onClick={() => this.add()}
                spin={createSecretRequest.inProgress}
                fill={true}
                data-test-id='security.add-api-key.add-btn'
              >
                <FormattedMessage id='add-api-key.add.btn' defaultMessage='Add' />
              </SpinButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlyoutFooter>
      </EuiFlyout>
    )
  }

  add = () => {
    const { newApiKeySetting, newApiKeyValue } = this.state
    const { addSecretToKeystore, closeFlyout } = this.props

    this.setState({ showSuccess: false })

    if (!isApiKeyRightFormat(newApiKeySetting)) {
      return this.setState({ errorOnAdding: true })
    }

    const payload: Keystore = {
      [`cluster.remote.${newApiKeySetting}.credentials`]: {
        value: newApiKeyValue,
      },
    }

    addSecretToKeystore(payload).then(() => {
      closeFlyout()
      this.setState({
        newApiKeySetting: ``,
        newApiKeyValue: ``,
        errorOnAdding: false,
        showSuccess: true,
      })
      addToast({
        ...toastText.secretCreationSuccess,
      })
    })
  }
}

export default AddApiKeyFlyout
