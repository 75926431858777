/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'

import { useEuiTheme } from '@elastic/eui'

import type { FC, ReactNode } from 'react'

interface Props {
  label?: ReactNode
}

const HorizontalSeparator: FC<Props> = ({ label }) => {
  const { euiTheme } = useEuiTheme()

  return (
    <div
      css={css([
        {
          color: euiTheme.colors.textSubdued,
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          '&:before, &:after': {
            content: '""',
            flex: 1,
            borderBottom: `1px solid ${euiTheme.colors.lightShade}`,
          },
        },

        label && {
          '&:before': {
            marginRight: '0.75em',
          },
          '&:after': {
            marginLeft: '0.75em',
          },
        },
      ])}
    >
      {label}
    </div>
  )
}

export default HorizontalSeparator
