/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import type { EuiDescriptionListProps } from '@elastic/eui'
import {
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiImage,
  EuiLink,
  EuiListGroup,
  EuiListGroupItem,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTextColor,
  useEuiTheme,
  useGeneratedHtmlId,
} from '@elastic/eui'

import {
  invalidateQueriesAfterTrialExtension,
  useGetEntitlementsV1,
} from '@modules/billing-lib/hooks'
import type { SaasUserResponse } from '@modules/cloud-api/v1/types'
import { addToast } from '@modules/cui/Toasts'
import { makeSsoUrl } from '@modules/project-lib/kibanaLinks'
import { getSaasEntityTrialDaysRemaining } from '@modules/profile-lib/trial'
import history from '@modules/utils/history'
import {
  useListElasticsearchProjectsQuery,
  useListObservabilityProjectsQuery,
  useListSecurityProjectsQuery,
} from '@modules/project-user-lib/hooks/list'

// eslint-disable-next-line import/no-restricted-paths
import { billingUsageUrl } from '@/apps/userconsole/urls'
// eslint-disable-next-line import/no-restricted-paths
import CreditCardModalButton from '@/apps/userconsole/components/Billing/CreditCard/CreditCardModalButton'

import ExtendTrialButton from './ExtendTrialButton'
import illustration from './illustration-analytics-speed-acceleration.svg'

type Props = {
  saasUser: SaasUserResponse
  onClose: () => void
}

const ServerlessTrialSummaryModal: React.FunctionComponent<Props> = ({ saasUser, onClose }) => {
  const organizationId = saasUser.user.organization_id

  const titleId = useGeneratedHtmlId()
  const { euiTheme } = useEuiTheme()

  const entitlementsQuery = useGetEntitlementsV1(organizationId, {
    enabled: Boolean(organizationId),
  })
  const elasticsearchQuery = useListElasticsearchProjectsQuery()
  const observabilityQuery = useListObservabilityProjectsQuery()
  const securityQuery = useListSecurityProjectsQuery()

  const project =
    elasticsearchQuery.data?.items[0] ||
    observabilityQuery.data?.items[0] ||
    securityQuery.data?.items[0]

  const daysRemaining = getSaasEntityTrialDaysRemaining(saasUser)

  const sidebarItems: EuiDescriptionListProps['listItems'] = []

  if (project) {
    sidebarItems.push({
      title: (
        <Fragment>
          <FormattedMessage
            id='serverlessTrialSummaryModal.instanceTitle'
            defaultMessage='Your instance'
          />
          <EuiSpacer size='s' />
        </Fragment>
      ),
      description: (
        <EuiLink href={makeSsoUrl(project)} external={true} target='_blank'>
          {project.name}
        </EuiLink>
      ),
    })
  }

  sidebarItems.push({
    title: (
      <Fragment>
        <FormattedMessage
          id='serverlessTrialSummaryModal.pricingTitle'
          defaultMessage='Understand pricing'
        />
        <EuiSpacer size='s' />
      </Fragment>
    ),
    description: (
      <Fragment>
        <EuiLink
          href={billingUsageUrl()}
          onClick={(e) => {
            e.preventDefault()
            history.push(billingUsageUrl())
            onClose()
          }}
        >
          <FormattedMessage
            id='serverlessTrialSummaryModal.pricingLink'
            defaultMessage='View your current usage'
          />
        </EuiLink>
        <EuiSpacer size='xs' />
        <EuiText size='s'>
          <FormattedMessage
            id='serverlessTrialSummaryModal.pricingLinkDescription'
            defaultMessage='See how pricing works based on your current trial usage.'
          />
        </EuiText>
      </Fragment>
    ),
  })

  const bullets = [
    <FormattedMessage
      id='serverlessTrialSummaryModal.bullets.1'
      defaultMessage='Unlimited scaling for workload size'
    />,
    <FormattedMessage
      id='serverlessTrialSummaryModal.bullets.2'
      defaultMessage='Fast, SLA-based support'
    />,
    <FormattedMessage
      id='serverlessTrialSummaryModal.bullets.3'
      defaultMessage='Additional features'
    />,
  ]

  return (
    <EuiModal
      onClose={onClose}
      style={{ width: `52rem` }}
      data-test-id='serverless-trial-summary-modal'
    >
      <EuiModalHeader>
        <EuiFlexGroup alignItems='center'>
          <EuiFlexItem grow={true}>
            <EuiModalHeaderTitle id={titleId}>
              <FormattedMessage
                id='serverlessTrialSummaryModal.title'
                defaultMessage='Trial overview'
              />
            </EuiModalHeaderTitle>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiTextColor color='warning'>
              <FormattedMessage
                id='serverlessTrialSummaryModal.daysRemaining'
                defaultMessage='{daysRemaining} {daysRemaining, plural, one {day} other {days}} left in your trial'
                values={{ daysRemaining }}
              />
            </EuiTextColor>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiFlexGroup alignItems='stretch'>
          <EuiFlexItem grow={4}>
            <EuiPanel color='subdued' hasShadow={false}>
              <EuiFlexGroup direction='column' alignItems='stretch'>
                <EuiFlexItem grow={true}>
                  <EuiFlexGroup gutterSize='s' direction='column' alignItems='center'>
                    <EuiFlexItem>
                      <EuiImage size='s' src={illustration} alt={''} />
                      <EuiSpacer size='m' />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiText textAlign='center'>
                        <strong>
                          <FormattedMessage
                            id='serverlessTrialSummaryModal.bodyIntro'
                            defaultMessage='Ready to subscribe?'
                          />
                        </strong>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiListGroup gutterSize='none' flush={true} wrapText={true} size='s'>
                        {bullets.map((label, i) => (
                          <EuiListGroupItem
                            key={i}
                            size='xs'
                            style={{ paddingLeft: 0 }}
                            iconType='checkInCircleFilled'
                            iconProps={{ color: euiTheme.colors.success }}
                            label={<EuiText size='s'>{label}</EuiText>}
                          />
                        ))}
                      </EuiListGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <CreditCardModalButton type='full' buttonProps={{ fill: true }}>
                    <FormattedMessage
                      id='serverlessTrialSummaryModal.subscribeButton'
                      defaultMessage='Subscribe'
                    />
                  </CreditCardModalButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiPanel>
          </EuiFlexItem>
          <EuiFlexItem grow={3}>
            <EuiPanel color='transparent'>
              <EuiDescriptionList rowGutterSize='m' listItems={sidebarItems} />
            </EuiPanel>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size='l' />
        <ExtendTrialButton
          organizationId={organizationId}
          onTrialExtended={() => {
            invalidateQueriesAfterTrialExtension()
            addToast({
              family: 'trialExtended',
              color: 'success',
              title: (
                <FormattedMessage
                  id='serverlessTrialSummaryModal.trialExtendedToast'
                  defaultMessage='Trial extended'
                />
              ),
            })
            onClose()
          }}
          isTrialExtendable={entitlementsQuery.data?.trial_extendable ?? false}
        />
      </EuiModalBody>
    </EuiModal>
  )
}

export default ServerlessTrialSummaryModal
