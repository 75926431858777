/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { PureComponent, Fragment } from 'react'

import { EuiImage } from '@elastic/eui'

import type { Image } from './types'

interface Props {
  images: Image[]
}

interface State {
  timeoutId?: number
  counter?: number
}

class CuiProductGif extends PureComponent<Props, State> {
  state = {
    counter: undefined,
    timeoutId: undefined,
  }

  componentDidMount(): void {
    this.startTimer()
  }

  componentWillUnmount(): void {
    const { timeoutId } = this.state
    window.clearTimeout(timeoutId)
  }

  render(): React.ReactElement {
    const { images } = this.props
    const { counter } = this.state

    const image = typeof counter === 'number' ? images[counter] : undefined

    return (
      <Fragment>
        {image && <EuiImage alt={image.alt} url={image.src} size='fullWidth' hasShadow={true} />}
      </Fragment>
    )
  }

  setTimer(counter: number): number {
    const { images } = this.props
    const image = images[counter]

    return window.setTimeout(this.startTimer, image?.interval)
  }

  startTimer = (): void => {
    const { images } = this.props
    const { counter } = this.state
    const lastImageIndex = images.length - 1
    const isCounterReset = typeof counter === 'undefined' || counter === lastImageIndex

    this.setState((prevState) => {
      const nextCount = isCounterReset ? 0 : prevState.counter! + 1

      return {
        counter: nextCount,
        timeoutId: this.setTimer(nextCount),
      }
    })
  }
}

export default CuiProductGif
