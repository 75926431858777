/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl'

import {
  EuiFlexItem,
  EuiFieldText,
  EuiButton,
  EuiFormRow,
  EuiSpacer,
  EuiButtonIcon,
  EuiCode,
  EuiFlexGroup,
} from '@elastic/eui'

import { CuiTable } from '@modules/cui/Table'

import { isApiKeyRightFormat } from '@/lib/apiKeys'
import DocLink from '@/components/DocLink'

import type { CuiTableColumn } from 'modules/cui/Table'
import type { WrappedComponentProps } from 'react-intl'
import type { ApiKey } from '../types'

type Props = WrappedComponentProps & {
  onChange: (apiKeys: ApiKey[]) => void
  showAccountTextOnly?: boolean
  deploymentId: string
}

type State = {
  apiKeys: ApiKey[]
  newApiKeySetting: string
  newApiKeyValue: string
  errorOnAdding: boolean
}

const messages = defineMessages({
  ariaLableRemove: {
    id: 'select-trust-level.rcs-2-add-key.table.remove-key',
    defaultMessage: 'Remove',
  },
})

class AddApiKeys extends React.Component<Props, State> {
  state: State = {
    apiKeys: [],
    newApiKeySetting: ``,
    newApiKeyValue: ``,
    errorOnAdding: false,
  }

  render(): JSX.Element {
    const {
      intl: { formatMessage },
    } = this.props
    const columns: Array<CuiTableColumn<ApiKey>> = [
      {
        textOnly: false,
        label: (
          <FormattedMessage
            id='select-trust-level.rcs-2-add-key.table.setting'
            defaultMessage='Setting name'
          />
        ),
        render: (apiKey) => <EuiCode>{apiKey.settingName}</EuiCode>,
      },
      {
        textOnly: false,
        label: (
          <FormattedMessage
            id='select-trust-level.rcs-2-add-key.table.value'
            defaultMessage='Secret'
          />
        ),
        width: `5rem`,
        render: () => <EuiCode>-</EuiCode>,
      },
      {
        actions: true,
        render: (apiKey) => (
          <EuiButtonIcon
            aria-label={`${formatMessage(messages.ariaLableRemove)}`}
            iconType='cross'
            onClick={() => this.remove(apiKey)}
          />
        ),
        width: `3rem`,
        align: `right`,
      },
    ]

    return (
      <div style={{ maxWidth: `28rem` }}>
        <EuiFormRow
          label={
            <FormattedMessage
              id='select-trust-level.rcs-2-add-key.setting-title'
              defaultMessage='Remote cluster name'
            />
          }
          isInvalid={this.state.errorOnAdding}
          error={
            <FormattedMessage
              id='select-trust-level.rcs-2-add-key.error'
              defaultMessage='This value must be lowercase and contain only letters, numbers, dashes, and underscores.'
            />
          }
          helpText={
            <FormattedMessage
              id='select-trust-level.rcs-2-add-key.help-text-2'
              defaultMessage="A unique identifier for the remote cluster. Must match the {boldRemoteClusterName} in this deployment's {boldStackManagement} settings."
              values={{
                boldRemoteClusterName: (
                  <strong>
                    <FormattedMessage
                      id='bold-remote-cluster-name'
                      defaultMessage='remote cluster name'
                    />
                  </strong>
                ),
                boldStackManagement: (
                  <strong>
                    <FormattedMessage
                      id='bold-stack-management'
                      defaultMessage='Stack Management -> Remote Clusters'
                    />
                  </strong>
                ),
              }}
            />
          }
        >
          <EuiFieldText
            onChange={(e) => this.setState({ newApiKeySetting: e.target.value })}
            value={this.state.newApiKeySetting}
          />
        </EuiFormRow>

        <EuiFormRow
          label={
            <FormattedMessage
              id='select-trust-level.rcs-2-add-key.value-title'
              defaultMessage='Cross-cluster API key (encoded format)'
            />
          }
          helpText={
            <FormattedMessage
              id='generate-cross-cluster-api-key.value.help.text'
              defaultMessage='Generate a cross-cluster API key in the remote cluster you’re connecting to. {learnMore}'
              values={{
                learnMore: (
                  <DocLink link='KibanaApiGenerationKey' showExternalLinkIcon={true}>
                    <FormattedMessage
                      id='api-key-generation.learn-more'
                      defaultMessage='Learn more'
                    />
                  </DocLink>
                ),
              }}
            />
          }
        >
          <EuiFieldText
            onChange={(e) => this.setState({ newApiKeyValue: e.target.value })}
            value={this.state.newApiKeyValue}
          />
        </EuiFormRow>

        <EuiFormRow>
          <EuiFlexGroup justifyContent='flexEnd'>
            <EuiFlexItem grow={false} style={{ width: `6em` }}>
              <EuiButton size='s' onClick={() => this.add()}>
                <FormattedMessage
                  id='select-trust-level.rcs-2-add-key.add-btn'
                  defaultMessage='Add'
                />
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        {this.state.apiKeys.length > 0 && (
          <div style={{ maxWidth: `50rem` }}>
            <EuiSpacer size='s' />
            <CuiTable<ApiKey> rows={this.state.apiKeys} columns={columns} />
          </div>
        )}
      </div>
    )
  }

  add = () => {
    const { newApiKeySetting, newApiKeyValue, apiKeys } = this.state

    if (
      !isApiKeyRightFormat(newApiKeySetting) ||
      apiKeys.find((key) => key.settingName === newApiKeySetting)
    ) {
      return this.setState({ errorOnAdding: true })
    }

    const newApiKey: ApiKey = {
      settingName: `cluster.remote.${newApiKeySetting}.credentials`,
      secret: newApiKeyValue,
    }

    this.props.onChange([...apiKeys, newApiKey])

    return this.setState({
      apiKeys: [...apiKeys, newApiKey],
      errorOnAdding: false,
      newApiKeySetting: ``,
      newApiKeyValue: ``,
    })
  }

  remove = (apiKey: ApiKey) => {
    this.setState({ apiKeys: this.state.apiKeys.filter((key) => key !== apiKey) })
    this.props.onChange(this.state.apiKeys.filter((key) => key !== apiKey))
  }
}

export default injectIntl(AddApiKeys)
