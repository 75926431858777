/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMemo } from 'react'

import { type Theme, getChartsTheme } from '@elastic/charts'
import { useEuiTheme } from '@elastic/eui'

/**
 * A hook used to get the `@elastic/charts` theme based on the current eui theme.
 */
export const useElasticChartsTheme = (): Theme => {
  const { euiTheme, colorMode } = useEuiTheme()
  return useMemo(
    () => getChartsTheme(euiTheme.themeName, colorMode),
    [colorMode, euiTheme.themeName],
  )
}
