/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import type { ApiErrorCollection } from '@modules/query/types'

export const PROJECT_TYPES = [`elasticsearch`, `observability`, `security`] as const

export type ProjectType = (typeof PROJECT_TYPES)[number]
export type ProjectID = string // pretty redundant but we need a generic (non-admin, non-user) reference

export type RouterProjectType = ProjectType | ':projectType' // for route-matching purposes

export type ProjectApiError = ApiErrorCollection | string
