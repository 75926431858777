/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx } from '@emotion/react'
import { useState, useEffect, useRef, Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { parse } from 'query-string'
import { noop } from 'lodash'
import { useLocation } from 'react-router'

import { EuiFieldText, EuiFormRow, EuiSpacer } from '@elastic/eui'

import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import { submitButtonStyle } from '@modules/access-management-components/styles'
import { useGetOrganizationSsoFindIdp } from '@modules/security-idp-lib/hooks'

import { initializeAuthForm } from '@/lib/authForm'
import GoogleReCaptchaProvider from '@/apps/userconsole/components/GoogleReCaptchaProvider'
import { getCreateUserPayload } from '@/components/CreateAccountForm/lib'

import SpinButton from '../../SpinButton'
import OpenIdSignUp from '../OpenIdSignUp'
import TermsOfService from '../../CreateAccountForm/TermsOfService'
import PasswordField from '../../PasswordField'
import CreateAccountFormErrorCallout from '../../CreateAccountForm/CreateAccountFormErrorCallout'
import GovcloudNotice from '../../UserAuthNotice/GovcloudNotice'
import validateEmail from '../../../lib/validateEmail'

import SsoSwitch from './SsoSwitch'
import { fieldText } from './messages'

import type { ReactElement, ChangeEvent, FocusEvent, FormEvent, FC } from 'react'
import type { CreateUserOptions, AllProps as Props } from './types'

import './userRegistrationForm.scss'

const UserRegistrationForm: FC<Props> = ({
  loginRequest,
  createUserRequest,
  createMarketplaceUserRequest,
  formId,
  isGovCloud,
  showSocialLogin,
  source,
  isMarketplace,
  redirectTo,
  isFlowV2,
  createUser,
  createPendingSaasUser,
  resetCreatePendingSassUserRequest,
  resetCreateSassUserRequest,
  isSwitchingToSso = false,
  setIsSwitchingToSso = () => noop(),
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<ReactElement | null>(null)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [onSubmitVerifyCaptcha, setOnSubmitVerifyCaptcha] = useState(() => noop)
  const {
    data: idpData,
    refetch: fetchIdpList,
    status: idpListQueryStatus,
    isFetching: isFetchingIdpList,
  } = useGetOrganizationSsoFindIdp({ email, options: { enabled: false, cacheTime: 0 } })
  const formRef = useRef<HTMLFormElement | null>(null)
  const { formatMessage } = useIntl()
  const { search } = useLocation()

  useEffect(() => {
    if (isFetchingIdpList) {
      return
    }

    if (idpListQueryStatus === 'success' && idpData.idps.length > 0) {
      setIsSwitchingToSso(true)
      return
    }

    setIsSwitchingToSso(false)

    if (
      idpListQueryStatus === 'error' ||
      (idpListQueryStatus === 'success' && idpData.idps.length === 0)
    ) {
      onSubmitVerifyCaptcha()
      return
    }
  }, [idpListQueryStatus, isFetchingIdpList])

  useEffect(() => {
    initializeAuthForm({
      form: formRef.current,
      onFormReady: (autoFillDetected) => {
        if (autoFillDetected && formRef.current) {
          const formElements = formRef.current.elements as any
          const usernameInput = formElements.email
          const passwordInput = formElements.password
          setEmail(usernameInput.value)
          setPassword(passwordInput.value)
        }
      },
    })
  }, [])

  const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const onChangePassword = (input: HTMLInputElement, options: { isValidPassword: boolean }) => {
    setPassword(input.value)
    setIsValidPassword(options.isValidPassword)
  }

  const isValidEmail = (): boolean => validateEmail(email)

  const validateField = (e: FocusEvent<HTMLInputElement>) => {
    const value = e.target.value

    if (value && !validateEmail(value)) {
      setError(
        <FormattedMessage
          id='cloud-sign-up-form.email-error'
          defaultMessage='Please use a valid email'
        />,
      )
    }
  }

  const clearError = () => {
    setError(null)
  }

  const onVerifyCaptcha = (captchaToken: string, options: CreateUserOptions = {}) => {
    onCreateUser(captchaToken, options)
    return
  }

  const onCreateUser = (captchaToken: string, { isActivatePendingUser }: CreateUserOptions) => {
    if (!captchaToken) {
      return
    }

    if (isActivatePendingUser) {
      return createPendingSaasUser({
        email,
        password,
        ...getCreateUserPayload(search),
        captcha_token: captchaToken,
      })
    }

    return createUser({ email, password, captcha_token: captchaToken })
  }

  const onSubmit = (
    e: FormEvent,
    verifyCaptcha: (options: CreateUserOptions) => void,
    options: CreateUserOptions = {},
  ) => {
    e.preventDefault()
    resetCreatePendingSassUserRequest()
    resetCreateSassUserRequest()

    if (isMarketplace || options.isActivatePendingUser) {
      verifyCaptcha(options)
      return
    }

    setOnSubmitVerifyCaptcha(() => verifyCaptcha)
    fetchIdpList()
  }

  const { partner } = parse(search.slice(1))
  const useSecondaryButtonLabel =
    source === 'training' ||
    source === 'community' ||
    source === 'support' ||
    source === 'partners' ||
    partner

  const onSignUpWithEmail = (verifyCaptcha: (options: CreateUserOptions) => void) => {
    setIsSwitchingToSso(false)
    verifyCaptcha({})
  }

  return (
    <div id='cloud-signup-form-wrapper-id' className='cloud-signup-form-wrapper'>
      <GoogleReCaptchaProvider action='registration' onVerifyCaptcha={onVerifyCaptcha}>
        {({ verifyCaptcha, isVerifyingCaptcha, captchaError }) => {
          const requestInProgress =
            createUserRequest.inProgress ||
            loginRequest.inProgress ||
            createMarketplaceUserRequest.inProgress ||
            isVerifyingCaptcha ||
            isFetchingIdpList

          return isSwitchingToSso ? (
            <SsoSwitch
              onSignUpWithEmail={() => onSignUpWithEmail(verifyCaptcha)}
              email={email}
              onGoBack={() => {
                setPassword('')
                setIsValidPassword(false)
                setIsSwitchingToSso(false)
              }}
            />
          ) : (
            <Fragment>
              <form
                id={formId}
                name={formId}
                onSubmit={(e) => onSubmit(e, verifyCaptcha)}
                className='cloud-signup-form'
                data-test-id='cloud-signup-form'
                ref={formRef}
              >
                <PrivacySensitiveContainer>
                  <EuiFormRow
                    isInvalid={!!error}
                    error={error}
                    fullWidth={true}
                    display='rowCompressed'
                    label={!isFlowV2 && <FormattedMessage {...fieldText.email} />}
                  >
                    <EuiFieldText
                      data-test-subj='email-input-id'
                      name='email'
                      icon={isFlowV2 ? 'email' : 'user'}
                      value={email}
                      fullWidth={true}
                      isInvalid={!!error}
                      onFocus={clearError}
                      onBlur={validateField}
                      onChange={onChangeEmail}
                      disabled={requestInProgress}
                      placeholder={isFlowV2 ? formatMessage(fieldText.email) : undefined}
                      aria-label={formatMessage(fieldText.email)}
                    />
                  </EuiFormRow>
                  <EuiSpacer size='m' />
                  <PasswordField
                    password={password}
                    hidePlaceholder={!isFlowV2}
                    fullWidth={true}
                    name='password'
                    label={!isFlowV2 && <FormattedMessage {...fieldText.password} />}
                    onChange={onChangePassword}
                    hasStrengthIndicator={true}
                    disabled={requestInProgress}
                    aria-label={formatMessage(fieldText.password)}
                  />
                  <CreateAccountFormErrorCallout
                    captchaError={captchaError}
                    stageActivation={(e) =>
                      onSubmit(e, verifyCaptcha, { isActivatePendingUser: true })
                    }
                  />
                </PrivacySensitiveContainer>
                {!createUserRequest.error && <EuiSpacer size='m' />}
                <SpinButton
                  type='submit'
                  disabled={!isValidPassword || !isValidEmail()}
                  fill={true}
                  buttonProps={{ fullWidth: true }}
                  spin={requestInProgress || isFetchingIdpList}
                  css={submitButtonStyle}
                  data-test-id='create-account-form-button'
                  data-track-id={`signup-button-v${isFlowV2 ? '2' : '1'}`}
                >
                  {useSecondaryButtonLabel ? (
                    <FormattedMessage
                      data-test-id='cloud-signup-secondary-button'
                      id='cloud-signup.secondary-button-label'
                      defaultMessage='Create account'
                    />
                  ) : (
                    <FormattedMessage
                      data-test-id='cloud-signup-default-button'
                      id='cloud-signup-page.form.button-submit'
                      defaultMessage='Sign up with email'
                    />
                  )}
                </SpinButton>
                {isFlowV2 && <EuiSpacer size='m' />}
              </form>
              {showSocialLogin && (
                <OpenIdSignUp
                  disabled={requestInProgress}
                  redirectTo={redirectTo}
                  isFlowV2={isFlowV2}
                />
              )}
            </Fragment>
          )
        }}
      </GoogleReCaptchaProvider>
      {!isSwitchingToSso && (
        <Fragment>
          <EuiSpacer size={isFlowV2 ? 'l' : 'm'} />
          <TermsOfService />
          {isGovCloud && (
            <Fragment>
              <EuiSpacer />
              <GovcloudNotice isSignup={true} />
            </Fragment>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default UserRegistrationForm
