/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'
// eslint-disable-next-line no-restricted-imports
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { parse } from 'query-string'
import { FormattedMessage } from 'react-intl'

import { EuiText } from '@elastic/eui'

import history from '@modules/utils/history'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import MfaForm from '@modules/mfa-login/MfaForm'
import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'
import ModalPage from '@modules/cui/ModalPage'

import { applyGoogleTrackingV4FormSubmission } from '@/apps/userconsole/lib/googleTracking'
import { buildFirstSignInRedirectUrl, buildLoginUrl } from '@/lib/urlUtils'
import EmailVerification from '@/components/Login/EmailVerification'
import AppLoadingRoot from '@/components/AppLoadingRoot'

import { getCreateUserPayload, isRequireActivationError } from '../CreateAccountForm/lib'

import { formId } from './Form'
import SignUpPage from './SignUpPage'
import { GovCloudSignUpPage } from './GovCloudSignUpPage'

import type { AllProps } from './types'

type State = {
  createdButVerificationRequired: boolean
  email: string | undefined
  loadedFlags: boolean
}

class UserRegistration extends Component<AllProps, State> {
  state: State = {
    email: undefined,
    loadedFlags: false,
    createdButVerificationRequired: false,
  }

  componentDidMount() {
    this.onLoadedFlags()
  }

  componentDidUpdate() {
    this.onLoadedFlags()
  }

  render() {
    const {
      downloads,
      isGovCloud,
      source,
      isLaunchDarklyActivated,
      locationQueryString,
      location,
      resetLoginRequest,
      mfa,
      onMfaSuccess,
      location: { search },
    } = this.props

    const { email, loadedFlags } = this.state

    const redirectTo = buildFirstSignInRedirectUrl(search)

    if (this.shouldVerifyEmail() && email) {
      return (
        <EmailVerification
          location={location}
          onCancel={() => {
            resetLoginRequest()
            return history.push(buildLoginUrl({ locationQueryString }))
          }}
          email={email}
        />
      )
    }

    if (mfa && mfa.mfa_required) {
      const footer = (
        <EuiText size='s' color='subdued'>
          <FormattedMessage
            id='mfaEnforcement.footer'
            defaultMessage='Need assistance? <link>Contact our Support team</link>'
            values={{
              link: (content) => <a href='mailto:support@elastic.co'>{content}</a>,
            }}
          />
        </EuiText>
      )

      return (
        <ModalPage footer={footer}>
          <PrivacySensitiveContainer>
            <MfaForm
              devices={mfa.mfa_devices as SaasAuthMfaDeviceResponse[]}
              stateId={mfa.state_id!}
              isFlowV2={false}
              onSuccess={(data) => {
                onMfaSuccess({
                  ...data,
                  redirectTo,
                })
              }}
            />
          </PrivacySensitiveContainer>
        </ModalPage>
      )
    }

    if (isGovCloud) {
      return (
        <GovCloudSignUpPage createUser={this.createUser} source={source} downloads={downloads} />
      )
    }

    if (isLaunchDarklyActivated && !loadedFlags) {
      return <AppLoadingRoot />
    }

    return (
      <SignUpPage
        createUser={this.createUser}
        isMarketplaceSignupEnabled={true}
        redirectTo={redirectTo}
      />
    )
  }

  createUser = async ({ email, password, captcha_token }) => {
    const {
      createUser,
      createSaasActivatedUser,
      location: { search },
      googleTrackingIdV4Enabled,
    } = this.props

    // if there's an onboarding token, we explicitly pre-activate
    const { onboarding_token } = parse(search.slice(1))
    const createUserFn = onboarding_token ? createSaasActivatedUser : createUser

    const { payload } = await createUserFn({
      password,
      email,
      captcha_token,
      ...getCreateUserPayload(search),
    })

    if (googleTrackingIdV4Enabled) {
      applyGoogleTrackingV4FormSubmission({
        email,
        formId,
        userId: payload?.user.user_id.toString(),
      })
    }

    if (!payload) {
      return // sanity
    }

    // if there's no need to activate, either because we insisted above or because the backend declared it so, then we chain to a login
    if (payload.user.require_email_verification) {
      this.setState({ createdButVerificationRequired: true, email })
    } else {
      this.login({ username: email, password })
    }
  }

  login = ({ username, password }) => {
    const {
      loginAndRedirect,
      location: { search },
    } = this.props
    // For current implementation, when OKTA sends the user back to our signup page, there is no user session,
    // it expects that on success we redirect the user back to them(OKTA). The fromURI encodes where the user
    // should be redirected to from OKTA on authentication
    const redirectTo = buildFirstSignInRedirectUrl(search)

    return loginAndRedirect({
      oktaRedirectUrl: redirectTo,
      redirectTo,
      email: username,
      password,
    })
  }

  shouldVerifyEmail(): boolean {
    const { loginRequest, registrationRequest } = this.props
    const { createdButVerificationRequired } = this.state
    const { error: loginError, inProgress: loginInProgress } = loginRequest
    const { error: registrationError, inProgress: registrationInProgress } = registrationRequest

    // registration errors mean we should not verify yet but login error could means we have to verify
    if (loginInProgress || registrationInProgress || registrationError) {
      return false
    }

    if (createdButVerificationRequired) {
      return createdButVerificationRequired
    }

    if (!loginError) {
      return false
    }

    const needsActivation = isRequireActivationError(loginError)

    return needsActivation
  }

  async onLoadedFlags() {
    const { isLaunchDarklyActivated, ldClient, launchDarklyClientInitializationTimeoutSeconds } =
      this.props
    const { loadedFlags } = this.state

    if (isLaunchDarklyActivated && !loadedFlags) {
      await ldClient?.waitForInitialization(launchDarklyClientInitializationTimeoutSeconds)

      this.setState({ loadedFlags: true })
    }
  }
}

export default withLDConsumer()(UserRegistration)
