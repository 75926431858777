/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiFieldText,
  EuiFieldPassword,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiLink,
} from '@elastic/eui'

import history from '@modules/utils/history'
import { CuiAlert } from '@modules/cui/Alert'
import PrivacySensitiveContainer from '@modules/cui/PrivacySensitiveContainer'
import HorizontalSeparator from '@modules/access-management-components/HorizontalSeparator'
import { submitButtonStyle } from '@modules/access-management-components/styles'
import AuthContext from '@modules/auth/context'
import { acceptInviteUrl } from '@modules/auth/urls'

import { initializeAuthForm } from '@/lib/authForm'
import { getConfigForKey } from '@/store'
import { forgotPasswordUrl } from '@/lib/urlBuilder'
import OAuthLoginError from '@/components/Login/OAuthLoginError'
import { buildSignInLink, buildSignInQuery } from '@/lib/urlUtils'
import SsoSignUp from '@/components/CreateAccountForm/SsoSignUp'

import GoogleSignUp from '../../../../CreateAccountForm/GoogleSignUp'
import MicrosoftSignUp from '../../../../CreateAccountForm/MicrosoftSignUp'
import SpinButton from '../../../../SpinButton'

import type { LDFlagSet } from 'launchdarkly-react-client-sdk'
import type { Location } from 'history'
import type { ReactElement, ReactNode, SyntheticEvent } from 'react'

export type Props = {
  location: Location<any>
  error: ReactNode | null
  username: string
  password: string
  updateUsername
  updatePassword
  onSubmit: (e: SyntheticEvent) => void
  inProgress: boolean
  registrationButtons: boolean
  openIdRedirectUrl?: string
  registerUrl?: string
  isGovCloud?: boolean
  hasOAuthLoginError?: boolean
  isFlowV2?: boolean
  ldFlags: LDFlagSet
  ssoLoginIdentifier?: string
}

type State = {
  hideOauthLoginError: boolean
  isSubmittable: boolean
}

const fieldsText = {
  usernameText: {
    id: 'login.username-text',
    defaultMessage: 'Username',
  },
  emailText: {
    id: 'login.email-text',
    defaultMessage: 'Email',
  },
  passwordText: {
    id: 'login.password-text',
    defaultMessage: 'Password',
  },
}

export class LoginForm extends Component<Props, State> {
  context!: React.ContextType<typeof AuthContext>

  state: State = {
    hideOauthLoginError: false,
    isSubmittable: false,
  }

  formRef: HTMLFormElement | null = null

  componentDidMount(): void {
    initializeAuthForm({
      form: this.formRef,
      onFormReady: (autoFillDetected) => {
        this.setState({
          isSubmittable: autoFillDetected,
        })
      },
    })
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { username, password } = prevProps

    if (this.shouldUpdateSubmittableState(username, password)) {
      this.isSubmittable()
    }
  }

  render(): ReactElement {
    const {
      error,
      username,
      password,
      updateUsername,
      updatePassword,
      inProgress,
      registrationButtons,
      openIdRedirectUrl,
      registerUrl,
      isGovCloud,
      isFlowV2,
      ssoLoginIdentifier,
      location: { pathname },
    } = this.props
    const { isSubmittable } = this.state
    const isEce = getConfigForKey(`CLOUD_UI_APP`) === `cloud-enterprise-adminconsole`
    const usernameText = isEce ? fieldsText.usernameText : fieldsText.emailText

    const usernamePlaceholder = isFlowV2 ? usernameText.defaultMessage : undefined
    const passwordPlaceholder = isFlowV2 ? fieldsText.passwordText.defaultMessage : undefined

    const spacerSize = isFlowV2 ? 'm' : 'l'

    // If the user is coming from an invitation link and there is no ssoLoginIdentifier, we disable the SSO button
    const isFromInvitation = pathname.startsWith(acceptInviteUrl())
    const isSsoDisabled = isFromInvitation && !ssoLoginIdentifier
    return (
      <form
        onSubmit={this.onSubmit}
        className='login-form-password'
        data-test-id='login-form-password'
        ref={(el) => {
          this.formRef = el
        }}
      >
        <PrivacySensitiveContainer>
          <EuiFormRow
            label={!isFlowV2 && <FormattedMessage {...usernameText} />}
            className='login-form-email'
            data-test-id='username-form-row'
          >
            <EuiFieldText
              data-test-id='login-username'
              icon={isFlowV2 ? 'email' : 'user'}
              value={username}
              onChange={updateUsername}
              name='email'
              placeholder={usernamePlaceholder}
              aria-label={usernamePlaceholder}
              fullWidth={true}
            />
          </EuiFormRow>

          <EuiSpacer size={spacerSize} />

          <EuiFormRow
            className='login-form-password-field'
            label={!isFlowV2 && <FormattedMessage {...fieldsText.passwordText} />}
          >
            <EuiFieldPassword
              data-test-id='login-password'
              value={password}
              onChange={updatePassword}
              type='dual'
              name='password'
              placeholder={passwordPlaceholder}
              aria-label={passwordPlaceholder}
              fullWidth={true}
            />
          </EuiFormRow>
        </PrivacySensitiveContainer>

        <EuiSpacer size={spacerSize} />

        <SpinButton
          type='submit'
          data-test-id='login-button'
          data-track-id={`login-button-v${isFlowV2 ? '2' : '1'}`}
          css={submitButtonStyle}
          color={error ? `danger` : `primary`}
          fill={true}
          spin={inProgress}
          disabled={!isSubmittable}
          buttonProps={{ fullWidth: true }}
        >
          <FormattedMessage id='login-login-form.log-in' defaultMessage='Log in' />
        </SpinButton>

        {!isFlowV2 && this.renderForgotPassword()}

        {this.renderError()}

        {registrationButtons && registerUrl && !isGovCloud && (
          <Fragment>
            {!isFlowV2 && (
              <Fragment>
                <EuiSpacer />

                <HorizontalSeparator
                  label={
                    <EuiText size='s'>
                      <FormattedMessage id='login-form.or-google' defaultMessage='Or log in with' />
                    </EuiText>
                  }
                />
              </Fragment>
            )}

            <EuiSpacer size={spacerSize} />

            <EuiFlexGroup alignItems='center' gutterSize='s'>
              {!isGovCloud && (
                <Fragment>
                  <EuiFlexItem>
                    <GoogleSignUp
                      fullText={false}
                      redirectTo={openIdRedirectUrl}
                      isFlowV2={isFlowV2}
                    />
                  </EuiFlexItem>
                  <EuiFlexItem>
                    <MicrosoftSignUp
                      fullText={false}
                      redirectTo={openIdRedirectUrl}
                      isFlowV2={isFlowV2}
                    />
                  </EuiFlexItem>
                </Fragment>
              )}

              {!isSsoDisabled && (
                <EuiFlexItem>
                  <SsoSignUp ssoLoginIdentifier={ssoLoginIdentifier} isFlowV2={isFlowV2} />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </Fragment>
        )}

        {isFlowV2 && this.renderForgotPassword()}
      </form>
    )
  }

  renderForgotPassword() {
    const {
      registrationButtons,
      location: { search },
    } = this.props
    const query = buildSignInQuery({ search, withReferrer: false })

    const forgotPasswordLink = buildSignInLink(forgotPasswordUrl(), query)

    if (registrationButtons) {
      return (
        <Fragment>
          <EuiSpacer />

          <EuiText textAlign='center' size='s' className='forgotPasswordLink'>
            <EuiLink
              href={forgotPasswordLink}
              onClick={(e) => {
                e.preventDefault()
                history.push(forgotPasswordLink)
              }}
              data-test-id='forgot-password-link'
            >
              <FormattedMessage id='login-form.forgot-password' defaultMessage='Forgot password?' />
            </EuiLink>
          </EuiText>
        </Fragment>
      )
    }

    return null
  }

  renderError(): ReactNode | null {
    const {
      error,
      location: { state: locationState },
      hasOAuthLoginError,
    } = this.props
    const { hideOauthLoginError } = this.state

    if (!error && !hasOAuthLoginError) {
      return null
    }

    if (hasOAuthLoginError) {
      const oAuthLoginError = locationState.oAuthLoginError

      return (
        <Fragment>
          {error && this.renderErrorAlert(error)}

          <OAuthLoginError oAuthLoginError={oAuthLoginError} hidden={hideOauthLoginError} />
        </Fragment>
      )
    }

    return this.renderErrorAlert(error)
  }

  renderErrorAlert(error): ReactElement {
    return (
      <Fragment>
        <EuiSpacer />

        <CuiAlert data-test-id='login-error' type='error'>
          {error}
        </CuiAlert>
      </Fragment>
    )
  }

  static contextType = AuthContext

  shouldUpdateSubmittableState(prevUsername: string, prevPassword: string): boolean {
    const { username, password } = this.props
    return prevUsername !== username || prevPassword !== password
  }

  isSubmittable(): void {
    const { username, password } = this.props
    this.setState({
      isSubmittable: !!username.trim() && !!password.trim(),
    })
  }

  onSubmit = (e: SyntheticEvent): void => {
    const { hasOAuthLoginError, onSubmit } = this.props

    if (hasOAuthLoginError) {
      this.setState({ hideOauthLoginError: true })
    }

    this.context.setAuthContext({ method: 'username-and-password' })

    onSubmit(e)
  }
}
