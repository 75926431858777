/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'
import { Component, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { map, isEmpty } from 'lodash'

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiSelect,
  EuiLoadingSpinner,
  EuiFormRow,
  EuiButtonEmpty,
  EuiSpacer,
} from '@elastic/eui'

import history from '@modules/utils/history'
import type { RepositoryConfig } from '@modules/cloud-api/v1/types'
import type { AsyncRequestState, RegionId, StackDeployment } from '@modules/ui-types'
import { CuiAlert } from '@modules/cui/Alert'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import { replaceIn, updateIn } from '@/lib/immutability-helpers'
import {
  hasEnabledSnapshots,
  getSnapshotRepositoryIdFromGet,
} from '@/lib/stackDeployments/selectors/snapshots'
import {
  getRegionId,
  getFirstEsClusterFromGet,
} from '@/lib/stackDeployments/selectors/fundamentals'

import SpinButton from '../../../SpinButton'
import DangerButton from '../../../DangerButton'
import { createSnapshotRepositoryUrl } from '../../../../lib/urlBuilder'
import schedule from '../../../../lib/schedule'

export type StateProps = {
  fetchSnapshotRepositoriesRequest: AsyncRequestState
  snapshotRepositories?: { [repoId: string]: RepositoryConfig }
  setDeploymentResourceMetadataRequest: AsyncRequestState
}

export type DispatchProps = {
  fetchSnapshotRepositories: (regionId: RegionId) => void
  resetSetDeploymentResourceMetadata: () => void
  updateMetadata: ({
    deployment,
    updater,
  }: {
    deployment: StackDeployment
    updater: (metadata: { [key: string]: any }) => { [key: string]: any }
    requestMeta?: { [key: string]: any }
  }) => void
}

export type ConsumerProps = {
  deployment: StackDeployment
}

export type Props = StateProps & DispatchProps & ConsumerProps

type State = {
  snapshotRepositoryId: string
}

const setSnapshotRepositoryType = `set-snapshot-repository`
const disableSnapshotRepositoryType = `disable-snapshot-repository`

class UpdateSnapshotRepository extends Component<Props, State> {
  state: State = {
    snapshotRepositoryId:
      getSnapshotRepositoryIdFromGet({ deployment: this.props.deployment }) || ``,
  }

  componentWillUnmount() {
    const { resetSetDeploymentResourceMetadata } = this.props
    resetSetDeploymentResourceMetadata()
  }

  render() {
    const {
      deployment,
      snapshotRepositories,
      setDeploymentResourceMetadataRequest,
      fetchSnapshotRepositoriesRequest,
    } = this.props

    const setSnapshotRepositoryRequest =
      setDeploymentResourceMetadataRequest.meta.type === setSnapshotRepositoryType
        ? setDeploymentResourceMetadataRequest
        : null

    const disableSnapshotsForClusterRequest =
      setDeploymentResourceMetadataRequest.meta.type === disableSnapshotRepositoryType
        ? setDeploymentResourceMetadataRequest
        : null

    const savedSnapshotRepositoryId = getSnapshotRepositoryIdFromGet({
      deployment: this.props.deployment,
    })

    const { snapshotRepositoryId } = this.state

    const enabledSnapshots = this.hasEnabledSnapshots()

    if (fetchSnapshotRepositoriesRequest.error) {
      return (
        <CuiAlert data-test-id='fetch-snapshot-error' type='error'>
          {fetchSnapshotRepositoriesRequest.error}
        </CuiAlert>
      )
    }

    if (!snapshotRepositories) {
      return <EuiLoadingSpinner />
    }

    const noSnapshotRepositoriesDefined = isEmpty(snapshotRepositories)

    if (noSnapshotRepositoriesDefined) {
      return (
        <CuiAlert iconType='faceSad' type='warning'>
          <FormattedMessage
            id='cluster-manage-update-snapshot-repository.no-repos'
            defaultMessage='You do not have any snapshot repositories set up. {addLink} to enable snapshots for your Elasticsearch clusters.'
            values={{
              addLink: (
                <PermissionsGate
                  permissions={[{ type: 'snapshot-repository', action: 'update', id: '*' }]}
                >
                  {({ hasPermissions }) => (
                    <EuiButtonEmpty
                      disabled={!hasPermissions}
                      onClick={() =>
                        history.push(createSnapshotRepositoryUrl(getRegionId({ deployment })!))
                      }
                    >
                      <FormattedMessage
                        id='cluster-manage-update-snapshot-repository.no-repos-link'
                        defaultMessage='Add a repository'
                      />
                    </EuiButtonEmpty>
                  )}
                </PermissionsGate>
              ),
            }}
          />
        </CuiAlert>
      )
    }

    return (
      <Fragment>
        <EuiFormRow
          label={
            <FormattedMessage
              id='cluster-manage-update-snapshot-repository.title'
              defaultMessage='Snapshot repository'
            />
          }
        >
          <EuiFlexGroup gutterSize='s'>
            <EuiFlexItem css={css({ maxWidth: '400px' })}>
              <EuiSelect
                data-test-id='update-snapshot-repo-for-deployment'
                value={snapshotRepositoryId}
                onChange={(e) => this.setState({ snapshotRepositoryId: e.target.value })}
                options={[
                  ...(snapshotRepositoryId ? [] : [{ text: `` }]),
                  ...map(snapshotRepositories, (repo) => ({
                    value: repo.repository_name,
                    text: repo.config.type
                      ? `${repo.repository_name} (${repo.config.type})`
                      : repo.repository_name,
                  })),
                ]}
              />
            </EuiFlexItem>

            <EuiFlexItem grow={false}>
              <PermissionsGate
                permissions={[
                  {
                    type: 'deployment',
                    action: 'update',
                    id: deployment.id,
                  },
                ]}
              >
                {({ hasPermissions }) => (
                  <SpinButton
                    data-test-id='save-snapshot-repo-button'
                    color='primary'
                    onClick={() => this.setSnapshotRepository()}
                    disabled={
                      !snapshotRepositoryId ||
                      (enabledSnapshots && snapshotRepositoryId === savedSnapshotRepositoryId) ||
                      !hasPermissions
                    }
                    spin={setSnapshotRepositoryRequest?.inProgress}
                  >
                    {enabledSnapshots ? (
                      <FormattedMessage
                        data-test-id='save-repo'
                        id='cluster-manage-update-snapshot-repository.save-repository-enabled'
                        defaultMessage='Save repository'
                      />
                    ) : (
                      <FormattedMessage
                        data-test-id='save-and-enable'
                        id='cluster-manage-update-snapshot-repository.save-repository-and-enable'
                        defaultMessage='Save repository and enable snapshots'
                      />
                    )}
                  </SpinButton>
                )}
              </PermissionsGate>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFormRow>

        {setSnapshotRepositoryRequest?.error && (
          <Fragment>
            <EuiSpacer size='s' />
            <CuiAlert type='error'>{setSnapshotRepositoryRequest.error}</CuiAlert>
          </Fragment>
        )}

        {setSnapshotRepositoryRequest?.isDone && (
          <Fragment>
            <EuiSpacer size='s' />
            <CuiAlert type='info'>
              <FormattedMessage
                id='cluster-manage-update-snapshot-repository.success-message'
                defaultMessage='This Elasticsearch cluster will now use the chosen snapshot repository.'
              />
            </CuiAlert>
          </Fragment>
        )}

        {enabledSnapshots && (
          <div>
            <EuiSpacer size='m' />
            <PermissionsGate
              permissions={[
                {
                  type: 'deployment',
                  action: 'update',
                  id: deployment.id,
                },
              ]}
            >
              {({ hasPermissions }) => (
                <DangerButton
                  disabled={!hasPermissions}
                  data-test-id='disable-snapshots-for-deployment'
                  onConfirm={() => this.disableSnapshotsForCluster()}
                  isBusy={disableSnapshotsForClusterRequest?.inProgress}
                  modal={{
                    title: (
                      <FormattedMessage
                        id='cluster-manage-disable-snapshot-repository.confirm-disable-snapshots'
                        defaultMessage='Confirm to disable snapshots'
                      />
                    ),
                    body: (
                      <FormattedMessage
                        id='cluster-manage-disable-snapshot-repository.explanation'
                        defaultMessage='Disabling snapshots can lead to data loss. Without a backup of your data, this Elasticsearch cluster will not be able to recover from failures.'
                      />
                    ),
                  }}
                >
                  <FormattedMessage
                    id='cluster-manage-disable-snapshot-repository.disable-snapshots'
                    defaultMessage='Disable snapshots'
                  />
                </DangerButton>
              )}
            </PermissionsGate>
            <EuiSpacer size='xs' />
          </div>
        )}

        {disableSnapshotsForClusterRequest?.error && (
          <Fragment>
            <EuiSpacer size='s' />
            <CuiAlert type='error'>{disableSnapshotsForClusterRequest.error}</CuiAlert>
          </Fragment>
        )}

        {disableSnapshotsForClusterRequest?.isDone && (
          <Fragment>
            <EuiSpacer size='s' />
            <CuiAlert type='info'>
              <FormattedMessage
                id='cluster-manage-disable-snapshot-repository.success-message'
                defaultMessage='This Elasticsearch cluster now has snapshots disabled.'
              />
            </CuiAlert>
          </Fragment>
        )}
      </Fragment>
    )
  }

  hasEnabledSnapshots() {
    const { deployment } = this.props
    const es = getFirstEsClusterFromGet({ deployment })!

    return hasEnabledSnapshots({ resource: es })
  }

  disableSnapshotsForCluster() {
    const { updateMetadata, deployment } = this.props

    updateMetadata({
      deployment,
      requestMeta: { type: disableSnapshotRepositoryType },
      updater: (metadata) => replaceIn(metadata, ['snapshot', 'enabled'], false),
    })
  }

  setSnapshotRepository() {
    const { snapshotRepositoryId } = this.state
    const { updateMetadata, deployment } = this.props

    updateMetadata({
      deployment,
      requestMeta: { type: setSnapshotRepositoryType },
      updater: (metadata) =>
        updateIn(metadata, ['snapshot'], (snapshot) => ({
          repository: {
            config: {
              snapshot_config_type: `reference`,
              repository_id: snapshotRepositoryId,
            },
          },
          enabled: true,
          slm: snapshot.slm,
        })),
    })
  }
}

export default schedule(
  UpdateSnapshotRepository,
  ({ deployment, fetchSnapshotRepositories }) =>
    fetchSnapshotRepositories(getRegionId({ deployment })!),
  [[`deployment`]],
)
