/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
/** @jsx jsx */

import { FormattedMessage } from 'react-intl'
import { css, jsx } from '@emotion/react'
import { Fragment, useState, type FunctionComponent } from 'react'

import {
  EuiTitle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  useEuiTheme,
  EuiSkeletonText,
  EuiText,
  EuiPanel,
} from '@elastic/eui'

import LandingPageOuterContainer from '@modules/access-management-components/LandingPageOuterContainer'
import LandingPageInnerContainer from '@modules/access-management-components/LandingPageInnerContainer'
import LandingPageSeparator from '@modules/access-management-components/HorizontalSeparator'
import { useFlagsWhenLoaded } from '@modules/launchdarkly'
import { useOnboardingToken } from '@modules/discovery-questions-lib/hooks'

import MarketplaceSignUp from './MarketplaceSignUp'
import UserRegistrationForm from './Form'

import type { LandingPageContext } from '../LandingPageContainer/types'

interface Props {
  createUser: ({ email, password, captcha_token }) => Promise<any>
  isServerlessUserRegistration?: boolean
  isMarketplaceSignupEnabled?: boolean
  redirectTo?: string
}

const getContentAndSettings = (isSwitchingSso: boolean) => {
  if (isSwitchingSso) {
    return {
      panelCssOverride: css({ maxWidth: '500px', alignSelf: 'center' }),
      v1LandingPageInnerContainerPanelMaxWidth: '500px',
      footer: (
        <EuiText size='s' color='subdued' textAlign='center'>
          <FormattedMessage
            id='mfaEnforcement.footer'
            defaultMessage='Need assistance? <link>Contact our Support team</link>'
            values={{
              link: (content) => <a href='mailto:support@elastic.co'>{content}</a>,
            }}
          />
        </EuiText>
      ),
    }
  }

  return {
    v1LandingPageInnerContainerPanelMaxWidth: 'none',
    title: (
      <FormattedMessage id='signup-cloud-wrapper.title' defaultMessage='Sign up for a free trial' />
    ),
    subtitle: (
      <FormattedMessage
        id='signup-cloud-wrapper.subtitle'
        defaultMessage='No credit card required'
      />
    ),
    pageContext: { name: 'signup' } as LandingPageContext,
  }
}

const SignUpPage: FunctionComponent<Props> = ({
  createUser,
  isServerlessUserRegistration,
  isMarketplaceSignupEnabled,
  redirectTo,
}: Props) => {
  const [isSwitchingToSso, setIsSwitchingToSso] = useState<boolean>(false)
  const onboardingToken = useOnboardingToken()
  const [isFlagUsable, flags] = useFlagsWhenLoaded({ isNonAuthPage: true })

  const { signUpFlowV2 } = flags

  const isFlowV2 = signUpFlowV2 || isServerlessUserRegistration || onboardingToken

  const { euiTheme } = useEuiTheme()

  const showMarketplaceSignUp = isMarketplaceSignupEnabled && !isSwitchingToSso

  if (!isFlagUsable) {
    return (
      <EuiPanel color='transparent' paddingSize='xl'>
        <EuiSkeletonText lines={8} />
      </EuiPanel>
    )
  }

  const {
    title,
    subtitle,
    pageContext,
    v1LandingPageInnerContainerPanelMaxWidth,
    panelCssOverride,
    footer,
  } = getContentAndSettings(!!isSwitchingToSso)

  if (isFlowV2) {
    return (
      <LandingPageOuterContainer pageContext={pageContext} isFlowV2={isFlowV2} footer={footer}>
        <EuiFlexItem grow={false} style={{ alignItems: 'center' }}>
          <EuiFlexGroup alignItems='center' direction='column' gutterSize='m'>
            <EuiFlexItem>
              <LandingPageInnerContainer
                title={title}
                isFlowV2={isFlowV2}
                panelCssOverride={panelCssOverride}
              >
                <UserRegistrationForm
                  createUser={createUser}
                  isFlowV2={isFlowV2}
                  redirectTo={redirectTo}
                  isSwitchingToSso={isSwitchingToSso}
                  setIsSwitchingToSso={setIsSwitchingToSso}
                />

                {showMarketplaceSignUp && (
                  <Fragment>
                    <EuiSpacer size='l' />

                    <LandingPageSeparator
                      label={
                        <EuiTitle size='m'>
                          <FormattedMessage id='signup-page.separator' defaultMessage='OR' />
                        </EuiTitle>
                      }
                    />
                  </Fragment>
                )}
              </LandingPageInnerContainer>
            </EuiFlexItem>
            {showMarketplaceSignUp && <MarketplaceSignUp isFlowV2={isFlowV2} />}
          </EuiFlexGroup>
        </EuiFlexItem>
      </LandingPageOuterContainer>
    )
  }

  return (
    <LandingPageOuterContainer pageContext={pageContext}>
      <EuiFlexItem grow={false} style={{ alignItems: 'center' }}>
        <EuiFlexGroup
          style={{ maxWidth: 1074, width: '100%' }}
          alignItems='flexStart'
          direction='row'
          gutterSize='l'
        >
          <EuiFlexItem>
            <LandingPageInnerContainer
              title={title}
              subtitle={subtitle}
              panelProps={{
                style: { margin: 0, maxWidth: v1LandingPageInnerContainerPanelMaxWidth },
                paddingSize: 'l',
              }}
              panelCssOverride={panelCssOverride}
            >
              <UserRegistrationForm
                createUser={createUser}
                isSwitchingToSso={isSwitchingToSso}
                setIsSwitchingToSso={setIsSwitchingToSso}
              />
            </LandingPageInnerContainer>
          </EuiFlexItem>

          {!isSwitchingToSso && (
            <Fragment>
              <EuiFlexItem
                grow={false}
                css={css(`
              align-items: center;
              color: ${euiTheme.colors.textSubdued},
              width: 90px;
              height: 275px;
              paddingTop: 21px;
                &::before,
                &::after {
                  content: '';
                  flex: 1;
                  border-right: 1px solid ${euiTheme.colors.lightShade};
                }
              &::before {
                margin-bottom: .75em;
              }
              &::after {
                margin-top: .75em;
              }
              @media only screen (min-width: ${euiTheme.breakpoint.m}px): {
                height: 60px;
                padding-top: 0;
              }
            `)}
              >
                <EuiTitle size='m'>
                  <FormattedMessage id='signup-page.separator' defaultMessage='OR' />
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem>
                <MarketplaceSignUp />
              </EuiFlexItem>
            </Fragment>
          )}
        </EuiFlexGroup>
      </EuiFlexItem>
    </LandingPageOuterContainer>
  )
}

export default SignUpPage
