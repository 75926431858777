/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useEffect, useRef } from 'react'

import { useEbtContext } from '@modules/ebt/EbtContext'
import { EventRegistry } from '@modules/ebt/EventRegistry'

type EventRegistryConstructorArg<T extends string, S extends Record<string, any>> = Omit<
  ConstructorParameters<typeof EventRegistry<T, S>>[0],
  'analytics'
>

type ReportEbtEventFn<
  TReportEbtEventType extends string,
  TReportEbtEventArgs extends Record<string, any>,
> = (type: TReportEbtEventType, args: TReportEbtEventArgs) => void

/**
 * A reusable React hook that manages event-based tracking (EBT) functionality.
 *
 * @template TReportEbtEventType - The type of events that can be reported (usually, a string or a union of strings).
 * Must be a snake_case string.
 * @template TReportEbtEventArgs - The type of the payload sent with the events
 *
 * @param eventRegistryArgs - An array of event registry configurations, each containing one event type and schema properties
 *
 * @returns A function to report the EBT events. The function takes an event type and an object with the event arguments.
 *
 * @example
 * ```typescript
 * const reportEbtEvent = useEbt([{
 *   eventType: 'my_event',
 *   schemaProps: { someArg: { description: 'Some argument' } },
 * }]);
 *
 * reportEbtEvent('my_event', { someArg: 'value' });
 * ```
 */
const useEbt = <
  TReportEbtEventType extends string,
  TReportEbtEventArgs extends Record<string, any>,
>(
  ...eventRegistryArgs: Array<EventRegistryConstructorArg<TReportEbtEventType, TReportEbtEventArgs>>
): ReportEbtEventFn<TReportEbtEventType, TReportEbtEventArgs> => {
  const { analytics } = useEbtContext()
  const hasBeenRegister = useRef(false)

  useEffect(() => {
    if (analytics && !hasBeenRegister.current) {
      hasBeenRegister.current = true

      try {
        eventRegistryArgs.forEach((item) => {
          const registry = new EventRegistry<TReportEbtEventType, TReportEbtEventArgs>({
            ...item,
            analytics,
          })

          registry.registerEvent()
        })
      } catch (e) {
        if (process.env.NODE_ENV !== 'production') {
          console.error(e)
        }
      }
    }
  }, [analytics, eventRegistryArgs])

  const reportEbtEvent = (type: TReportEbtEventType, args: TReportEbtEventArgs) => {
    try {
      if (analytics) {
        analytics.reportEvent(type, args)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return reportEbtEvent
}

export default useEbt
