/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'

import { EuiFlexItem, EuiSpacer, EuiFlexGrid, EuiFlexGroup } from '@elastic/eui'

import useBillingDetails from '@modules/billing-lib/billingDetails/useBillingDetails'
import {
  isDrawdown,
  isMonthly,
  isPrepaidConsumptionCustomer,
  isInactive,
  isTrial,
} from '@modules/billing-lib/billingDetails/utils'
import { useConfig } from '@modules/cui/ConfigContext'

import SubscriptionDetailsCard from '@/components/User/BillingOverviewV2/components/SubscriptionDetailsCard'
import OrganizationPrepaids from '@/components/Organization/OrganizationPrepaids'

import UsageCardV2 from '../components/UsageCardV2'
import PrepaidConsumptionOverviewCard from '../components/PrepaidConsumptionOverviewCard'
import PrepaidAccountDetailsPanel from '../../BillingOverview/PrepaidAccountDetailsPanel'

import MonthlyOrDrawdownOverviewPanel from './MonthlyOrDrawdownOverviewPanel'
import InactiveOverviewPanel from './InactiveOverviewPanel'
import TrialOverviewPanel from './TrialOverviewPanel'

import type { ReactElement } from 'react'

interface MarketplacesOverviewProps {
  organizationId: string
}

function MarketplacesOverview({ organizationId }: MarketplacesOverviewProps): ReactElement {
  const billingDetails = useBillingDetails()
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'

  const renderOverviewPanel = (): JSX.Element | null => {
    // sanity
    if (!billingDetails.data) {
      return null
    }

    if (isTrial(billingDetails.data)) {
      return <TrialOverviewPanel organizationId={organizationId} />
    }

    if (isPrepaidConsumptionCustomer(billingDetails.data)) {
      return <PrepaidConsumptionOverviewCard organizationId={organizationId} />
    }

    if (isMonthly(billingDetails.data) || isDrawdown(billingDetails.data)) {
      return <MonthlyOrDrawdownOverviewPanel organizationId={organizationId} />
    }

    if (isInactive(billingDetails.data)) {
      return <InactiveOverviewPanel />
    }

    return null
  }

  return (
    <Fragment>
      <EuiSpacer size='m' />

      <EuiFlexGrid columns={3} gutterSize='m'>
        {/* Usage Panel */}
        <EuiFlexItem>
          <UsageCardV2 organizationId={organizationId} simplifyView={true} />
        </EuiFlexItem>

        {/* Overview Panel */}
        <EuiFlexItem>{renderOverviewPanel()}</EuiFlexItem>

        {/* Subscription panel*/}
        <EuiFlexItem>
          <SubscriptionDetailsCard />
        </EuiFlexItem>
      </EuiFlexGrid>

      <EuiSpacer size='m' />

      <EuiFlexGroup gutterSize='s'>
        <EuiFlexItem grow={4} />
        <EuiFlexItem grow={8}>
          <PrepaidAccountDetailsPanel hasBorder={true} organizationId={organizationId} />
          <EuiSpacer size='m' />
          {isAdminConsole && <OrganizationPrepaids organizationId={organizationId} />}
        </EuiFlexItem>
      </EuiFlexGroup>
    </Fragment>
  )
}

export default MarketplacesOverview
