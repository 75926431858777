/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Component } from 'react'

import type { UserProfile } from '@modules/ui-types'

import UsageNotice from './UsageNotice'

export interface Props {
  loggedIn: boolean
  hasPermissions: boolean
  accountDetails: UserProfile | undefined
  fetchAccountDetails: () => void
  enableUsageNoticeElasticCheck: boolean
}

class NotificationBanner extends Component<Props> {
  componentDidMount() {
    const { accountDetails, fetchAccountDetails, hasPermissions, loggedIn } = this.props

    if (!loggedIn) {
      return
    }

    if (!accountDetails && hasPermissions) {
      fetchAccountDetails()
    }
  }

  componentDidUpdate(prevProps): void {
    const { accountDetails, fetchAccountDetails, hasPermissions } = this.props

    if (!accountDetails && !prevProps.hasPermissions && hasPermissions) {
      fetchAccountDetails()
    }
  }

  render() {
    const { accountDetails, hasPermissions, loggedIn, enableUsageNoticeElasticCheck } = this.props

    if (!loggedIn) {
      return null
    }

    if (!hasPermissions) {
      return null
    }

    if (!accountDetails) {
      return null
    }

    return (
      <UsageNotice
        accountDetails={accountDetails}
        enableUsageNoticeElasticCheck={enableUsageNoticeElasticCheck}
      />
    )
  }
}

export default NotificationBanner
