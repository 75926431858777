/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { useMutation, useQuery } from 'react-query'

import { useConfig } from '@modules/cui/ConfigContext'
import {
  createOrganization,
  getOrganization,
  getSaasUser,
  getUserProfile,
  postRefreshToken,
} from '@modules/profile-api'
import { queryClient } from '@modules/query'
import type { QueryFunctionReturnType, QueryHook, QueryHookOptions } from '@modules/query/types'
import { BadRequestException } from '@modules/ts-essentials'
import type { UserProfileFromApi } from '@modules/ui-types'
import { throwErrorUnlessProduction } from '@modules/utils/error'

import { enhanceUserProfileFromApi } from '..'

const getUserProfileQueryKey = 'getUserProfile'
const getOrganizationQueryKey = 'getOrganization'
const getSaasUserQueryKey = 'getSaasUser'

/**
 * This hook should be called only in UserConsole.
 * In AdminConsole, it will throw BadRequestException.
 * To prevent exception, please provide proper value for the options.enabled param.
 */
export const useGetUserProfileQuery: QueryHook<typeof getUserProfile> = (
  options?: QueryHookOptions,
) => {
  const isAdminConsole = useConfig('APP_NAME') === 'adminconsole'
  const isQueryEnabled = options?.enabled ?? true

  if (isQueryEnabled && isAdminConsole) {
    throwErrorUnlessProduction(
      new BadRequestException(
        'This hook is only available for User Console. Please provide proper value for the options.enabled param to disable this hook in AdminConsole.',
      ),
    )
  }

  const queryOptions = {
    ...options,
    enabled: isAdminConsole ? false : options?.enabled,
  }

  return useQuery(getUserProfileQueryKey, () => getUserProfile(), queryOptions)
}

export const invalidateGetUserProfileQuery = () =>
  queryClient.invalidateQueries(getUserProfileQueryKey)

export const useCreateOrganizationMutation = ({ name }: { name?: string }) =>
  useMutation<QueryFunctionReturnType<typeof createOrganization>, { message }>({
    mutationFn: () => createOrganization({ name }),
    onSuccess: () => postRefreshToken(),
  })

/**
 * This hook should be called only in UserConsole.
 * In AdminConsole, it will throw BadRequestException.
 * To prevent exception, please provide proper value for options.enabled param.
 */
export const useProfile = (options?: QueryHookOptions): UserProfileFromApi | undefined =>
  useGetUserProfileQuery(options).data?.user

export const useSaasUser: (
  options?: QueryHookOptions,
) => Awaited<ReturnType<typeof getSaasUser>> | undefined = (options) =>
  useQuery(getSaasUserQueryKey, () => getSaasUser(), options).data

export const useGetOrganizationQuery: QueryHook<typeof getOrganization> = (
  organizationId: string,
  options?: QueryHookOptions,
) =>
  useQuery(
    [getOrganizationQueryKey, organizationId],
    () => getOrganization({ organizationId }),
    options,
  )

export const invalidateGetOrganizationQuery = () =>
  queryClient.invalidateQueries({ queryKey: [getOrganizationQueryKey] })

export const useEnhancedUserProfile = () => enhanceUserProfileFromApi(useProfile())

export const invalidateGetSaasUserQuery = () =>
  queryClient.invalidateQueries({ queryKey: [getSaasUserQueryKey] })
