/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiPanel, EuiSpacer, EuiText, EuiButtonGroup } from '@elastic/eui'

import { CuiAlert } from '@modules/cui/Alert'
import { useGetCostsItemsV2 } from '@modules/billing-lib/hooks'
import { processQueryParams } from '@modules/billing-api/utils'
import { useServerlessGroupBy } from '@modules/billing-lib/featureflags'

import messages from '../../messages'

import { PeriodOptions } from './constants'
import { PeriodTab } from './types'
import {
  getPreviousMonthDates,
  getCurrentMonthDates,
  getPrettyCurrentMonthDates,
  getPrettyPreviousMonthDates,
} from './utils'
import SimplifyUsageSummary from './SimplifyUsageSummary'
import UsageSummary from './UsageSummary'

import type { FunctionComponent } from 'react'

interface Props {
  organizationId: string
  simplifyView?: boolean
}

const UsageCard: FunctionComponent<Props> = ({ organizationId, simplifyView }) => {
  const [selectedTab, setSelectedTab] = useState<PeriodTab>(PeriodTab.Current)
  const queryDates = {
    [PeriodTab.Current]: getCurrentMonthDates(),
    [PeriodTab.Previous]: getPreviousMonthDates(),
  }[selectedTab]
  const { data, isLoading, isError } = useGetCostsItemsV2({
    pathParameters: { organization_id: organizationId },
    queryParameters: processQueryParams({
      ...queryDates,
      serverless_group_by: useServerlessGroupBy(),
    }),
  })

  const getTabTitle = (): string =>
    ({
      [PeriodTab.Current]: getPrettyCurrentMonthDates(),
      [PeriodTab.Previous]: getPrettyPreviousMonthDates(),
    }[selectedTab])

  if (isError) {
    return (
      <CuiAlert type='error' data-test-id='error-message'>
        <FormattedMessage {...messages.genericErrorMessage} />
      </CuiAlert>
    )
  }

  return (
    <EuiPanel hasShadow={false} paddingSize='l' hasBorder={true} data-test-id='usage-card'>
      <EuiButtonGroup
        legend='Month Selection'
        options={PeriodOptions}
        idSelected={selectedTab}
        onChange={(selectedTabId: PeriodTab) => setSelectedTab(selectedTabId)}
        buttonSize='compressed'
      />

      <EuiSpacer size='m' />

      <EuiText>
        <h3>{getTabTitle()}</h3>
      </EuiText>

      <EuiSpacer size='xl' />

      {simplifyView ? (
        <SimplifyUsageSummary isLoading={isLoading} data={data} />
      ) : (
        <UsageSummary isLoading={isLoading} data={data} />
      )}
    </EuiPanel>
  )
}

export default UsageCard
