/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { defineMessages } from 'react-intl'
import { matchRoutes, type RouteConfig } from 'react-router-config'
import { isEmpty } from 'lodash'

import { createItem } from '@modules/utils/navigation'
import { getRouteParams } from '@modules/utils/matchRoute'
import type { ProjectID } from '@modules/project-user-api/v1/types'
import { PROJECT_TYPES, type ProjectType } from '@modules/ui-types/projects'
import { getDisplayProjectId } from '@modules/project-lib/display'
import { projectUrl, projectsUrl } from '@modules/project-user-lib/urls'
import { useGetProjectQuery } from '@modules/project-user-lib/hooks/get'

import type { WrappedComponentProps } from 'react-intl'
import type { RouteComponentProps } from 'react-router'

const messages = defineMessages({
  projects: {
    id: `navigation.projects`,
    defaultMessage: `Projects`,
  },
  performance: {
    id: `navigation.projects.performance`,
    defaultMessage: `Performance`,
  },
  security: {
    id: `navigation.projects.security`,
    defaultMessage: `Security`,
  },
})

export type RoutingInfo = WrappedComponentProps & {
  routes: RouteConfig[]
  location: RouteComponentProps['location']
}

const ProjectName: React.FunctionComponent<{ projectType: ProjectType; projectId: ProjectID }> = ({
  projectType,
  projectId,
}) => {
  const { data } = useGetProjectQuery(projectType, projectId)
  return <span>{data?.name || getDisplayProjectId(projectId)}</span>
}

export function buildProjectsMenu(routingInfo: RoutingInfo) {
  const {
    intl: { formatMessage },
    routes,
    location,
  } = routingInfo

  const { projectId } = getRouteParams<{
    projectId: ProjectID
  }>(routes, location.pathname)

  const projectType = findProjectType(routingInfo)

  const projectsItem = createItem({
    'data-test-id': `projects-link`,
    name: formatMessage(messages.projects),
    href: projectsUrl(),
    items: [],
  })

  if (projectType && projectId) {
    projectsItem.items = [
      createItem({
        'data-test-id': `project-link`,
        name: <ProjectName projectType={projectType} projectId={projectId} />,
        href: projectUrl(projectType, projectId),
      }),
    ]
  }

  return projectsItem
}

export function findProjectType({ routes, location }: RoutingInfo): ProjectType | undefined {
  const findRoute = matchRoutes(routes, location.pathname)

  if (isEmpty(findRoute)) {
    return
  }

  const lastRoute = findRoute[findRoute.length - 1]

  if (isEmpty(lastRoute)) {
    return
  }

  if (PROJECT_TYPES.some((pt) => lastRoute.match.path.startsWith(projectUrl(pt, `:projectId`)))) {
    return lastRoute.route.projectType || undefined
  }

  return
}
