/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'

import Feature from '@modules/utils/feature'

import { getLoginRequest, createSaasUserRequest } from '@/reducers/asyncRequests/registry'
import { setInitialPasswordRequest } from '@/apps/userconsole/reducers/registry'
import { createSaasActivatedUser } from '@/actions/saasUsers'
import { setInitialPassword } from '@/apps/userconsole/actions/account'
import { loginAndRedirect } from '@/actions/auth'
import { getConfigForKey, isFeatureActivated } from '@/selectors'

import CreateAccountForm from './CreateAccountForm'

import type { ReduxState, ThunkDispatch } from '@/types/redux'
import type { StateProps, DispatchProps, ConsumerProps } from './types'

const mapStateToProps = (state: ReduxState): StateProps => ({
  createUserRequest: createSaasUserRequest(state),
  loginRequest: getLoginRequest(state),
  isGovCloud: isFeatureActivated(state, Feature.hideIrrelevantSectionsFromGovCloud),
  setInitialPasswordRequest: setInitialPasswordRequest(state),
  googleTrackingIdV4Enabled: getConfigForKey(state, `GOOGLE_ANALYTICS_TRACKING_ID_V4`) !== null,
})

const mapDispatchToProps = (
  dispatch: ThunkDispatch,
  { redirectTo }: ConsumerProps,
): DispatchProps => ({
  createUser: (user) => dispatch(createSaasActivatedUser(user)),
  loginAndRedirect: ({ credentials: { email, password } }) =>
    dispatch(
      loginAndRedirect({
        oktaRedirectUrl: redirectTo,
        redirectTo,
        credentials: { email, password },
      }),
    ),
  setInitialPassword: ({ email, expires, hash, password }) =>
    dispatch(setInitialPassword({ email, expires, hash, password, redirectTo })),
})

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAccountForm)
