/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React, { Fragment } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import type { EuiBasicTableColumn } from '@elastic/eui'
import {
  EuiCallOut,
  EuiHealth,
  EuiInMemoryTable,
  EuiSpacer,
  EuiText,
  useEuiTheme,
} from '@elastic/eui'

import { useDeleteRoleMappings, useUpdateRoleMappings } from '@modules/security-idp-lib/hooks'
import type { RoleAssignments, RoleMapping } from '@modules/cloud-api/v1/types'
import { addToast } from '@modules/cui/Toasts'

import RoleBadges from '@/components/Organization/RoleBadges'
import { organizationRoleMappingUrl } from '@/apps/userconsole/urls'

import { errorMessages, messages } from './messages'
import ConfirmRemovingRoleMappingModal from './ConfirmRemovingRoleMappingModal'

import type { ReactNode } from 'react'
import type { Props } from './types'

const sorting = {
  sort: {
    field: 'name',
    direction: 'asc',
  } as const,
}

const afterRemovingRoleMapping = ({
  removedMappingName,
  onSettled,
  setError,
}: {
  removedMappingName: string
  onSettled: () => void
  setError: (error: ReactNode) => void
}) => ({
  onSuccess: () => {
    addToast({
      family: 'organization.security.role-mappings-table.remove',
      color: 'success',
      iconType: 'check',
      title: (
        <FormattedMessage
          {...messages.toastRemoveRoleMappingSuccess}
          values={{
            roleMappingName: removedMappingName,
          }}
        />
      ),
    })
  },
  onError: (error) => {
    if (error.errors?.[0]?.code === 'org.role_mapping_rule.missing_owner_role') {
      setError(<FormattedMessage {...errorMessages.missingOrgOwnerRole} />)
      return
    }

    addToast({
      family: 'organization.security.role-mappings-table.remove',
      color: 'danger',
      iconType: 'alert',
      title: (
        <FormattedMessage
          {...messages.toastRemoveRoleMappingError}
          values={{ roleMappingName: removedMappingName }}
        />
      ),
    })
  },
  onSettled,
})

const RoleMappingTable = ({
  organizationId,
  isLoading,
  roleMappings = [],
  hasDeleteRoleMappingPermission,
  hasUpdateRoleMappingPermission,
  readonly,
}: Props) => {
  const { formatMessage } = useIntl()
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false)
  const [error, setError] = React.useState<ReactNode | null>(null)
  const [selectedRoleMapping, setSelectedRoleMapping] = React.useState<RoleMapping | null>(null)
  const { euiTheme } = useEuiTheme()
  const history = useHistory()
  const { mutate: updateRoleMappings, isLoading: isUpdatingRoleMappings } = useUpdateRoleMappings()
  const { mutate: deleteRoleMappings, isLoading: isDeletingRoleMappings } = useDeleteRoleMappings()

  const onClickRemoveRoleMapping = (roleMapping: RoleMapping) => {
    setSelectedRoleMapping(roleMapping)
    setIsConfirmationModalOpen(true)
  }

  const onRemoveRoleMapping = () => {
    setError(null)

    if (!organizationId || !selectedRoleMapping) {
      return
    }

    if (roleMappings.length === 1) {
      deleteRoleMappings(
        { organizationId },
        {
          ...afterRemovingRoleMapping({
            removedMappingName: selectedRoleMapping.name,
            setError,
            onSettled: () => {
              setIsConfirmationModalOpen(false)
            },
          }),
        },
      )
      return
    }

    const removedMappingName = selectedRoleMapping.name
    const updatedRoleMappings = roleMappings.filter(
      (mapping) => mapping.name !== removedMappingName,
    )
    updateRoleMappings(
      { organizationId, mappings: updatedRoleMappings },
      {
        ...afterRemovingRoleMapping({
          removedMappingName: selectedRoleMapping.name,
          setError,
          onSettled: () => {
            setIsConfirmationModalOpen(false)
          },
        }),
      },
    )
  }

  const onClickEditRoleMapping = (roleMapping: RoleMapping) => {
    history.push(`${organizationRoleMappingUrl()}/${roleMapping.name}`)
  }

  const columns: Array<EuiBasicTableColumn<RoleMapping>> = [
    {
      field: 'name',
      sortable: true,
      name: formatMessage(messages.nameColumn),
      render: (name: string) => (
        <EuiText size='s' color={euiTheme.colors.textPrimary}>
          {name}
        </EuiText>
      ),
    },
    {
      field: 'enabled',
      name: formatMessage(messages.statusColumn),
      render: (enabled: boolean) => (
        <Fragment>
          <EuiHealth color={enabled ? 'success' : 'subdued'} />
          <EuiText size='s'>
            {enabled
              ? formatMessage(messages.enabledStatus)
              : formatMessage(messages.disabledStatus)}
          </EuiText>
        </Fragment>
      ),
    },
    {
      field: 'role_assignments',
      name: formatMessage(messages.rolesColumn),
      render: (role_assignments: RoleAssignments) => (
        <RoleBadges roleAssignments={role_assignments} />
      ),
    },
  ]

  if (!readonly) {
    columns.push({
      name: formatMessage(messages.actionsColumn),
      align: 'right',
      actions: [
        {
          name: formatMessage(messages.editRoleMapping),
          description: formatMessage(messages.editRoleMapping),
          icon: 'pencil',
          color: 'primary',
          type: 'icon',
          isPrimary: true,
          onClick: onClickEditRoleMapping,
          enabled: () => hasUpdateRoleMappingPermission,
        },
        {
          name: formatMessage(messages.removeRoleMapping),
          description: formatMessage(messages.removeRoleMapping),
          icon: 'trash',
          color: 'danger',
          type: 'icon',
          onClick: onClickRemoveRoleMapping,
          enabled: () => hasDeleteRoleMappingPermission && hasUpdateRoleMappingPermission,
        },
      ],
    })
  }

  return (
    <Fragment>
      {error && (
        <div>
          <EuiCallOut color='danger' iconType='alert' size='s' heading='h6' title={error} />
          <EuiSpacer size='l' />
        </div>
      )}
      <EuiInMemoryTable
        loading={isLoading}
        message={
          (isLoading && formatMessage(messages.loadingRoleMappings)) ||
          (roleMappings.length === 0 && formatMessage(messages.noRoleMappings))
        }
        columns={columns}
        items={roleMappings}
        sorting={sorting}
      />
      {isConfirmationModalOpen && (
        <ConfirmRemovingRoleMappingModal
          roleMappingName={selectedRoleMapping?.name ?? ''}
          isLoading={isUpdatingRoleMappings || isDeletingRoleMappings}
          onRemoveRoleMapping={onRemoveRoleMapping}
          onClose={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </Fragment>
  )
}

export default RoleMappingTable
