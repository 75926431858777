/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { connect } from 'react-redux'
import { parse } from 'query-string'

import {
  getExchangeOrganizationSsoRequest,
  getInitiateOrganizationSsoRequest,
} from '@/reducers/asyncRequests/registry'
import {
  exchangeIdToken,
  initiateOrganizationSso,
  redirectAfterLogin,
  resetExchangeIdTokenRequest,
  resetInitiateOrganizationSsoRequest,
} from '@/actions/auth/auth'

import UserconsoleSso from './UserconsoleSso'

import type { ReduxState } from '@/types/redux'
import type { ConsumerProps, DispatchProps, StateProps } from './types'

const mapStateToProps = (
  state: ReduxState,
  {
    match: {
      params: { loginIdentifier },
    },
    location: { hash },
  }: ConsumerProps,
): StateProps => {
  const hashParams = parse(String(hash).slice(1))
  const idToken = typeof hashParams.id_token === `string` ? hashParams.id_token : undefined
  const oktaError = typeof hashParams.error === `string` ? hashParams.error : undefined
  const oktaErrorDescription =
    typeof hashParams.error_description === `string` ? hashParams.error_description : undefined
  const stateParam = typeof hashParams.state === `string` ? hashParams.state : undefined

  return {
    loginIdentifier,
    idToken,
    oktaError,
    oktaErrorDescription,
    state: stateParam,
    initiateSsoRequest: getInitiateOrganizationSsoRequest(state),
    exchangeSsoRequest: getExchangeOrganizationSsoRequest(state),
  }
}

const mapDispatchToProps: DispatchProps = {
  initiateSsoCheck: initiateOrganizationSso,
  exchangeIdToken,
  resetInitiateSsoCheck: resetInitiateOrganizationSsoRequest,
  resetExchangeIdToken: resetExchangeIdTokenRequest,
  redirectAfterLogin,
}

export default connect<StateProps, DispatchProps, ConsumerProps>(
  mapStateToProps,
  mapDispatchToProps,
)(UserconsoleSso)
