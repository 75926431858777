/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React from 'react'
import { FormattedMessage } from 'react-intl'

import { CuiButton } from '@modules/cui/Button'
import type { AsyncRequestError } from '@modules/ui-types'
import PermissionsGate from '@modules/permissions-components/PermissionsGate'

import InviteOrganizationMembersFlyout from '.'

import type { OrganizationMemberRow } from '../types'
import type { FunctionComponent } from 'react'

const InviteOrganizationMembersButton: FunctionComponent<{
  organizationId: string
  organizationMemberRows: OrganizationMemberRow[]
  error: AsyncRequestError | undefined
}> = ({ organizationId, organizationMemberRows, error }) => (
  <InviteOrganizationMembersFlyout
    organizationId={organizationId}
    organizationMemberRows={organizationMemberRows}
  >
    {({ openFlyout }) => (
      <PermissionsGate
        permissions={[
          {
            organizationId,
            type: 'organization-invitation',
            action: 'create',
          },
        ]}
      >
        {({ hasPermissions, isLoading }) => (
          <CuiButton
            fill={true}
            disabled={!hasPermissions || error !== undefined}
            spin={isLoading}
            onClick={() => openFlyout()}
            data-test-id='invite-organization-members-button'
          >
            <FormattedMessage
              id='organization.invite-organization-members-flyout.invite-members'
              defaultMessage='Invite members'
            />
          </CuiButton>
        )}
      </PermissionsGate>
    )}
  </InviteOrganizationMembersFlyout>
)

export default InviteOrganizationMembersButton
