/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import { find, isEmpty, omit, pick } from 'lodash'
import { parse, stringify } from 'query-string'

import { acceptInviteUrl, loginUrl } from '@modules/auth/urls'
import type { OrganizationInvitation } from '@modules/cloud-api/v1/types'
import type { UserSubscription } from '@modules/ui-types'
import { portalUrl } from '@modules/utils/rootUrls'

import {
  createDeploymentUrl,
  marketplaceRegisterUrl,
  redirectDiscoveryQuestions,
  registerUrl,
  serverlessDiscoveryQuestions,
} from './urlBuilder'

import type {
  InvitationSource,
  InvitationType,
} from '@/apps/userconsole/components/AcceptInvitation/types'
import type { LandingPageContext } from '@/components/LandingPageContainer/types'

export type MarketoParamsType = {
  ultron?: string
  blade?: string
  hulk?: string
  gambit?: string
  thor?: string
  camp?: string
  src?: string
  mdm?: string
  cnt?: string
  trm?: string
  baymax?: string
  storm?: string
  rogue?: string
  elektra?: string
  tech?: string
  plcmt?: string
  cta?: string
  pg?: string
  utm_campaign?: string
  utm_source?: string
  utm_medium?: string
  utm_term?: string
  utm_content?: string
  utm_id?: string
}

export type WelcomeImageIdentifier = NonNullable<RegistrationSource> | '' | 'login' | 'registration'

type SignInQueryData = {
  fromURI?: string
  redirectTo?: string
  settings?: string
  source?: RegistrationSource
  marketo: MarketoParamsType
  token?: string
  partner?: UserSubscription
  email?: string
  onboarding_token?: string
}

export type SignInQueryParams = MarketoParamsType & {
  fromURI?: string
  redirectTo?: string
  referrer?: string
  source?: RegistrationSource
  token?: string
  partner?: UserSubscription
  email?: string
}

const marketoParams = [
  'ultron',
  'blade',
  'hulk',
  'gambit',
  'thor',
  'camp',
  'src',
  'mdm',
  'cnt',
  'trm',
  'baymax',
  'storm',
  'rogue',
  'elektra',
  'tech',
  'plcmt',
  'cta',
  'pg',
  'utm_campaign',
  'utm_source',
  'utm_medium',
  'utm_term',
  'utm_content',
  'utm_id',
]

const sourceParamValues = [
  'cloud',
  'community-contributions',
  'community-events',
  'education',
  'partners',
  'support',
  'training',
  'training-checkout',
  'training-courses',
] as const

export type RegistrationSource = (typeof sourceParamValues)[number]

export const UserSubscriptionValues: UserSubscription[] = ['aws', 'gcp', 'azure']

export function buildSignInQuery({
  search,
  withReferrer,
}: {
  search: string
  withReferrer?: boolean
}): SignInQueryParams {
  const { fromURI, marketo, redirectTo, source, token, partner, email } = getSignInQueryData(search)
  const queryParams = getAuthQueryParams({
    fromURI,
    marketo,
    redirectTo,
    source,
    token,
    partner,
    email,
  })

  return withReferrer ? queryParams : omit(queryParams, 'referrer')
}

export function buildLoginUrl({ locationQueryString }: { locationQueryString: string }): string {
  const url = loginUrl()

  if (locationQueryString.length === 0) {
    return url
  }

  const query = buildSignInQuery({ search: locationQueryString })
  const queryString = stringify(query)

  return `${url}?${queryString}`
}

export function buildAcceptInvitationUrl({
  activationHash,
  source,
  pageContext,
}: {
  activationHash?: string
  source?: InvitationSource
  pageContext: LandingPageContext
}): string {
  const url = acceptInviteUrl()
  const queryString = stringify({ ah: activationHash, source, 'landing-page': pageContext.name })

  return `${url}?${queryString}`
}

export function buildAcceptInvitationRedirectUrl(search: string): string {
  const { redirectTo } = getSignInQueryData(search)

  if (redirectTo) {
    return redirectTo
  }

  return buildFirstSignInRedirectUrl(search)
}

export function buildAcceptOrganizationInvitationRedirectUrl({
  invitationType,
  organizationInvitation,
}: {
  invitationType: InvitationType
  organizationInvitation: OrganizationInvitation
}): string {
  const {
    token: invitationToken,
    organization: { id: invitationOrganizationId, name: invitationOrganizationName },
  } = organizationInvitation

  const url = portalUrl()

  const queryString = stringify({
    invitationType,
    invitationToken,
    invitationOrganizationId,
    invitationOrganizationName,
  })

  return `${url}?${queryString}`
}

export function buildRegisterUrl({ search }: { search: string }): string {
  const url = registerUrl()

  if (search.length === 0) {
    return url
  }

  const query = buildSignInQuery({ search })
  const queryString = stringify(query)

  return `${url}?${queryString}`
}

export function buildMarketplaceConversionRegisterUrl({ search }: { search: string }): string {
  const url = marketplaceRegisterUrl()

  if (search.length === 0) {
    return url
  }

  const { fromURI, partner } = buildSignInQuery({ search })
  const queryString = stringify({
    redirectTo: fromURI,
    partner,
  })

  return `${url}?${queryString}`
}

export function buildFirstSignInRedirectUrl(search: string): string {
  const { fromURI, onboarding_token: onboardingToken } = getSignInQueryData(search)

  if (fromURI) {
    return fromURI
  }

  return redirectDiscoveryQuestions({ onboardingToken, fromURI })
}

export function buildFirstSignInRedirectServerlessUrl(search: string, referrer: string): string {
  const { fromURI, onboarding_token: onboardingToken } = getSignInQueryData(search)

  return serverlessDiscoveryQuestions({ onboardingToken, fromURI, referrer })
}

export function buildOpenIdSignUpQuery(
  search: string,
  signInQueryDataOverride?: Partial<SignInQueryData>,
): SignInQueryParams {
  for (const key in signInQueryDataOverride) {
    if (signInQueryDataOverride[key] === undefined) {
      delete signInQueryDataOverride[key]
    }
  }

  const signInQueryData = { ...getSignInQueryData(search), ...signInQueryDataOverride }

  const { fromURI, marketo, redirectTo, source, settings } = signInQueryData

  const queryParams = getAuthQueryParams({ fromURI, marketo, redirectTo, source, settings })

  if (fromURI === portalUrl()) {
    return { ...queryParams, fromURI: createDeploymentUrl() }
  }

  return queryParams
}

function filterSourceParam(source: string): WelcomeImageIdentifier {
  if (!source) {
    return ''
  }

  const sourceValue = find(sourceParamValues, (key) => source === key)

  if (!sourceValue) {
    return ''
  }

  return sourceValue
}

export function getRegistrationSource(queryString: string) {
  const source = getSourceParam(queryString)

  if (source === `training` || source === `training-checkout` || source === `training-courses`) {
    return `training`
  }

  if (source === `community-contributions` || source === `community-events`) {
    return `community`
  }

  if (source === `support` || source === `partners`) {
    return source
  }

  return
}

function getSourceParam(queryString: string): WelcomeImageIdentifier {
  if (queryString.length === 0) {
    return ''
  }

  const { source } = parse(queryString.slice(1))

  if (typeof source !== 'string') {
    return ''
  }

  return filterSourceParam(source)
}

function getAuthQueryParams({
  fromURI,
  marketo,
  redirectTo,
  source,
  settings,
  token,
  partner,
  email,
}: SignInQueryData): SignInQueryParams {
  return {
    ...marketo,
    referrer: window.location.href,
    ...(typeof fromURI === 'string' ? { fromURI } : {}),
    ...(typeof redirectTo === 'string' ? { fromURI: redirectTo } : {}),
    ...(typeof source === 'string' ? { source } : {}),
    ...(typeof settings === 'string' ? { settings } : {}),
    ...(typeof token === 'string' ? { token } : {}),
    ...(typeof partner === 'string' ? { partner } : {}),
    ...(typeof email === 'string' ? { email } : {}),
  }
}

export function getSignInQueryData(search: string): SignInQueryData {
  const query = parse(search.slice(1))
  const marketo = pick(query, marketoParams)

  const { fromURI, redirectTo, settings, token, email, onboarding_token } = query
  const source = find(sourceParamValues, (key) => key === query.source)
  const partner = find(UserSubscriptionValues, (key) => key === query.partner)

  return {
    fromURI: typeof fromURI === `string` ? fromURI : undefined,
    redirectTo: typeof redirectTo === `string` ? redirectTo : undefined,
    settings: typeof settings === `string` ? settings : undefined,
    marketo,
    source,
    token: typeof token === `string` ? token : undefined,
    partner: typeof partner === `string` ? partner : undefined,
    email: typeof email === `string` ? email : undefined,
    onboarding_token: typeof onboarding_token === `string` ? onboarding_token : undefined,
  }
}

export function buildSignInLink(redirectPath: string, query?: SignInQueryParams): string {
  if (!query || isEmpty(query)) {
    return redirectPath
  }

  return `${redirectPath}?${stringify(query)}`
}
