/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

/** @jsx jsx */

import { jsx, css } from '@emotion/react'

import { EuiFlexItem, useEuiTheme } from '@elastic/eui'

import type { FunctionComponent } from 'react'

export const CuiFlexItemSeparator: FunctionComponent = () => {
  const { euiTheme } = useEuiTheme()

  return (
    <EuiFlexItem
      grow={false}
      css={css`
        border-left: 1px solid ${euiTheme.colors.lightShade};
      `}
    />
  )
}
