/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import cx from 'classnames'

import { EuiSpacer, EuiFlexGroup, EuiFlexItem } from '@elastic/eui'
import type { EuiTabbedContentTab } from '@elastic/eui'

import type { AsyncRequestError, RoutableBreadcrumb } from '@modules/ui-types'
import history from '@modules/utils/history'
import { isNonEmptyArray } from '@modules/ts-utils'

import LeaveOrganizationButton from '@/components/Organization/OrganizationMembers/LeaveOrganizationButton'
import InviteOrganizationMembersButton from '@/components/Organization/OrganizationMembers/InviteOrganizationMembersFlyout/InviteOrganizationMembersButton'
import OrganizationEmptyPrompt from '@/components/Organization/OrganizationEmptyPrompt/'
import OrganizationNoRolesEmptyPrompt from '@/components/Organization/OrganizationNoRolesEmptyPrompt'
import OrganizationMembers from '@/components/Organization/OrganizationMembers'
import AccountDetails from '@/apps/userconsole/components/AccountDetails'
import OrganizationName from '@/components/Organization/OrganizationName'
import ApiKeys from '@/components/ApiKeys'
import {
  accountContactsCrumbs,
  accountMembersCrumbs,
  accountCrumbs,
  accountApiKeysCrumbs,
  orgSecurityCrumbs,
} from '@/lib/crumbBuilder'
import { accountContactsUrl } from '@/apps/userconsole/urls'
import Security from '@/components/Security'

import PortalPage from '../PortalPage'

import type { TabbedContent } from '../PortalPage'
import type { ReactElement } from 'react'
import type { AllProps as Props } from './types'

class Account extends PureComponent<Props> {
  componentDidMount(): void {
    const {
      organizationId,
      showOrganization,
      fetchOrganizationIfNeeded,
      fetchOrganizationMembers,
      fetchOrganizationInvites,
    } = this.props

    if (!showOrganization) {
      history.replace(accountContactsUrl())
    }

    if (organizationId && showOrganization) {
      fetchOrganizationIfNeeded(organizationId)
      fetchOrganizationMembers(organizationId)
      fetchOrganizationInvites(organizationId)
    }
  }

  render(): ReactElement | null {
    const {
      isRouteFSTraced,
      organizationId,
      showOrganization,
      permissions: {
        hasViewMembersTabPermission,
        hasViewAccountTabPermission,
        hasViewApiKeysTabPermission,
      },
    } = this.props

    const hasNoPermissionsToSeeTabs =
      showOrganization &&
      organizationId &&
      !hasViewAccountTabPermission &&
      !hasViewMembersTabPermission &&
      !hasViewApiKeysTabPermission

    if (showOrganization && !organizationId) {
      return (
        <PortalPage
          breadcrumbs={accountCrumbs(true)}
          className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-account')}
        >
          <OrganizationEmptyPrompt />
        </PortalPage>
      )
    }

    if (hasNoPermissionsToSeeTabs) {
      return (
        <PortalPage
          className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-account')}
          contentHeader={this.renderContentHeader()}
        >
          <EuiSpacer size='l' />

          <OrganizationNoRolesEmptyPrompt />
        </PortalPage>
      )
    }

    const tabbedContent = this.getTabbedContent()
    const breadcrumbs = this.getBreadcrumbs(tabbedContent)

    return (
      <PortalPage
        breadcrumbs={breadcrumbs}
        className={cx({ 'fs-unmask': isRouteFSTraced }, 'cloud-portal-account')}
        contentHeader={this.renderContentHeader()}
        tabbedContent={tabbedContent}
      />
    )
  }

  renderContentHeader(): ReactElement {
    const { organizationId, showOrganization } = this.props

    if (showOrganization && organizationId) {
      return (
        <EuiFlexGroup>
          <EuiFlexItem>
            <OrganizationName organizationId={organizationId} />
          </EuiFlexItem>

          <EuiFlexItem grow={false}>{this.renderActionButtons()}</EuiFlexItem>
        </EuiFlexGroup>
      )
    }

    return <FormattedMessage id='cloud.account.title' defaultMessage='Account' />
  }

  renderActionButtons(): ReactElement | null {
    const { organization, organizationMemberRows, isLoading } = this.props

    if (organization === undefined || isLoading) {
      return null
    }

    return (
      <EuiFlexGroup gutterSize='m'>
        <EuiFlexItem grow={false}>
          <InviteOrganizationMembersButton
            organizationId={organization.id}
            organizationMemberRows={organizationMemberRows}
            error={this.getError(organization.id)}
          />
        </EuiFlexItem>

        <EuiFlexItem grow={false}>
          <LeaveOrganizationButton
            organization={organization}
            error={this.getError(organization.id)}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    )
  }

  renderTabContent(content: ReactElement): ReactElement {
    return (
      <Fragment>
        <EuiSpacer size='l' />

        {content}
      </Fragment>
    )
  }

  getTabbedContent(): TabbedContent | undefined {
    const {
      location,
      organizationId,
      showOrganization,
      organization,
      isLoading,
      permissions: {
        hasViewMembersTabPermission,
        hasViewAccountTabPermission,
        hasViewApiKeysTabPermission,
        hasAccessToSecurityTab,
      },
      organizationMemberRows,
    } = this.props
    const tabs: EuiTabbedContentTab[] = []
    const tabIdFromUrl = location.pathname.split('/').pop()

    if (showOrganization && organizationId && hasViewMembersTabPermission) {
      tabs.unshift({
        id: 'members',
        name: <FormattedMessage id='tabs.members' defaultMessage='Members' />,
        content: this.renderTabContent(
          <OrganizationMembers
            organizationId={organizationId}
            organization={organization}
            organizationMemberRows={organizationMemberRows}
            isLoading={isLoading}
            error={this.getError(organizationId)}
          />,
        ),
      })
    }

    if (!showOrganization || hasViewAccountTabPermission) {
      tabs.push({
        id: 'contacts',
        name: <FormattedMessage id='tabs.contacts' defaultMessage='Contacts' />,
        content: this.renderTabContent(
          <AccountDetails data-test-id='account-index-contacts-link' />,
        ),
      })
    }

    if (hasViewApiKeysTabPermission) {
      tabs.push({
        id: 'keys',
        name: <FormattedMessage id='tabs.apiKeys' defaultMessage='API keys' />,
        content: this.renderTabContent(<ApiKeys />),
      })
    }

    if (hasAccessToSecurityTab && organizationId) {
      tabs.push({
        id: 'idp',
        name: <FormattedMessage id='tabs.security' defaultMessage='Security' />,
        content: this.renderTabContent(<Security organizationId={organizationId} />),
      })
    }

    if (isNonEmptyArray(tabs)) {
      const initialSelectedTab = tabs.find(({ id }) => id === tabIdFromUrl)

      return { tabs, initialSelectedTab: initialSelectedTab ?? tabs[0] }
    }

    return
  }

  getBreadcrumbs(tabbedContent: TabbedContent | undefined): RoutableBreadcrumb[] {
    const { showOrganization } = this.props

    const crumbs = {
      contacts: accountContactsCrumbs,
      members: accountMembersCrumbs,
      keys: accountApiKeysCrumbs,
      idp: orgSecurityCrumbs,
    }

    if (tabbedContent === undefined) {
      return accountCrumbs(showOrganization)
    }

    return [...accountCrumbs(showOrganization), ...crumbs[tabbedContent.initialSelectedTab.id]()]
  }

  getError = (organizationId: string): AsyncRequestError | undefined => {
    const {
      fetchOrganizationRequest,
      fetchOrganizationMembersRequest,
      fetchOrganizationInvitationsRequest,
      permissions: {
        hasGetOrganizationPermission,
        hasListOrganizationMembersPermission,
        hasListOrganizationInvitationsPermission,
      },
    } = this.props

    const fetchOrganizationRequestError = hasGetOrganizationPermission
      ? fetchOrganizationRequest(organizationId).error
      : undefined

    const fetchOrganizationMembershipsRequestError = hasListOrganizationMembersPermission
      ? fetchOrganizationMembersRequest(organizationId).error
      : undefined

    const fetchOrganizationInvitationsRequestError = hasListOrganizationInvitationsPermission
      ? fetchOrganizationInvitationsRequest(organizationId).error
      : undefined

    return (
      fetchOrganizationRequestError ||
      fetchOrganizationMembershipsRequestError ||
      fetchOrganizationInvitationsRequestError
    )
  }
}

export default Account
