/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState, type FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import type { DefaultSolutionView } from '@modules/deployment-creation-wizard/types'
import DeploymentCreationWizardLayout from '@modules/deployment-creation-wizard/DeploymentCreationWizardLayout'

import { deploymentCreateCrumbs } from '@/lib/crumbBuilder'

import SolutionTypeAwareCreateStackDeploymentEditorWrapper from './SolutionTypeAwareCreateStackDeploymentEditorWrapper'
import DefaultSolutionViewSelector from './DefaultSolutionViewSelector'
import { toDefaultSolutionView } from './lib'
import useDeploymentCreationWizardSteps from './hooks'
import useEbtDeploymentCreationWizard from './use-ebt-deployment-creation-wizard'

const DeploymentCreationWizard: FunctionComponent = () => {
  const [currentStep, setCurrentStep, generateStepDetails] =
    useDeploymentCreationWizardSteps('solution-selector')

  const [defaultSolutionView, setDefaultSolutionView] = useState<DefaultSolutionView>('search')

  const { reportDeploymentUseCaseEbt } = useEbtDeploymentCreationWizard()

  const steps = generateStepDetails(currentStep)

  let title = (
    <FormattedMessage
      defaultMessage='Select a solution view for your deployment'
      id='deployment-creation-wizard.select-solution-view'
    />
  )

  let description = (
    <Fragment>
      <FormattedMessage
        defaultMessage='Since version 8.16, hosted deployments offer lighter and solution-based navigations, called solution views.'
        id='deployment-creation-wizard.solution-view-description.1'
      />
      <br />
      <FormattedMessage
        defaultMessage='Admins of this deployment can change the solution view from space settings later.'
        id='deployment-creation-wizard.solution-view-description.2'
      />
    </Fragment>
  )

  let child = (
    <DefaultSolutionViewSelector
      onChooseSolution={(label) => {
        setCurrentStep(`deployment-editor`)
        setDefaultSolutionView(toDefaultSolutionView(label))
        reportDeploymentUseCaseEbt(toDefaultSolutionView(label))
      }}
    />
  )

  if (currentStep === `deployment-editor`) {
    title = (
      <FormattedMessage
        defaultMessage='Confirm the settings of your deployment'
        id='deployment-creation-wizard.confirm-deployment-settings'
      />
    )

    description = (
      <FormattedMessage
        defaultMessage='Your data will be stored in the cloud provider of your choice.'
        id='deployment-creation-wizard.confirm-deployment-settings-description'
      />
    )

    child = (
      <SolutionTypeAwareCreateStackDeploymentEditorWrapper solutionView={defaultSolutionView} />
    )
  }

  return (
    <DeploymentCreationWizardLayout
      breadcrumbs={deploymentCreateCrumbs()}
      steps={steps}
      title={title}
      description={description}
    >
      {child}
    </DeploymentCreationWizardLayout>
  )
}

export default DeploymentCreationWizard
