/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'

import type { ProjectType } from '@modules/ui-types/projects'
import usePermissions from '@modules/permissions-lib/hooks'
import { useProfile } from '@modules/profile-lib/hooks'
import SelectSolutionCard from '@modules/solution-selector/SelectSolutionCard'

import type { FunctionComponent, ReactNode } from 'react'

type Props = {
  label: ProjectType
  description: ReactNode
  features: ReactNode[]
  logo: string
  title: ReactNode
  onNext: (label: ProjectType) => void
}

const SelectProjectTypeCard: FunctionComponent<Props> = ({
  label,
  description,
  features,
  logo,
  title,
  onNext,
}) => {
  const { hasPermissions } = usePermissions([{ type: `project-${label}`, action: 'create' }])

  const profile = useProfile()

  return (
    <SelectSolutionCard
      label={label}
      description={description}
      features={features}
      logo={logo}
      title={title}
      buttonDisabled={!!profile?.organization_id && !hasPermissions}
      onNext={onNext}
      dataAttributePrefix='project-select-solution-'
    />
  )
}

export default SelectProjectTypeCard
