/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { EuiFlexItem, EuiFlexGroup, EuiButton } from '@elastic/eui'

import type { StackDeployment } from '@modules/ui-types'
import history from '@modules/utils/history'

import { deploymentUrl } from '@/lib/urlBuilder'

import DeploymentCompletedMessage from '../../StackDeploymentActivity/DeploymentCompletedMessage'
import DeploymentFailedMessage from '../../StackDeploymentActivity/DeploymentFailedMessage'

import type { FunctionComponent } from 'react'

export type Props = {
  createFailed: boolean
  planInProgress: boolean
  showDeploymentCompletedMessage: boolean
  stackDeployment: StackDeployment
  deepLink: string | null
  disabled: boolean
  onContinue?: () => void
}

const DeploymentGettingStartedFooter: FunctionComponent<Props> = ({
  planInProgress,
  stackDeployment,
  createFailed,
  showDeploymentCompletedMessage,
  deepLink,
  disabled,
  onContinue,
}) => (
  <EuiFlexGroup responsive={false} aria-live='polite'>
    {showDeploymentCompletedMessage && (
      <EuiFlexItem grow={8} style={{ alignItems: 'flex-start', justifyContent: 'center' }}>
        <DeploymentCompletedMessage />
      </EuiFlexItem>
    )}

    {createFailed && (
      <EuiFlexItem grow={8}>
        <DeploymentFailedMessage />
      </EuiFlexItem>
    )}

    <EuiFlexItem grow={2} style={{ justifyContent: 'right' }}>
      <EuiFlexGroup justifyContent='flexEnd'>
        {deepLink ? (
          <EuiButton
            color='primary'
            isDisabled={disabled || planInProgress}
            onClick={() => {
              onContinue?.()

              window.location.href = deepLink
            }}
            fill={true}
            data-test-id='continue-button'
          >
            <FormattedMessage
              id='deployment-waiting-experience.continue.deep-link'
              defaultMessage='Continue'
            />
          </EuiButton>
        ) : (
          <EuiButton
            color='primary'
            isDisabled={disabled || planInProgress}
            onClick={() => {
              onContinue?.()

              history.replace(deploymentUrl(stackDeployment.id))
            }}
            fill={true}
            data-test-id='deployment-waiting-experience.overview-button'
          >
            <FormattedMessage
              id='deployment-waiting-experience.continue.no-deep-link'
              defaultMessage='Continue'
            />
          </EuiButton>
        )}
      </EuiFlexGroup>
    </EuiFlexItem>
  </EuiFlexGroup>
)

export default DeploymentGettingStartedFooter
