/*
 * ELASTICSEARCH CONFIDENTIAL
 * __________________
 *
 *  Copyright Elasticsearch B.V. All rights reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Elasticsearch B.V. and its suppliers, if any.
 * The intellectual and technical concepts contained herein
 * are proprietary to Elasticsearch B.V. and its suppliers and
 * may be covered by U.S. and Foreign Patents, patents in
 * process, and are protected by trade secret or copyright
 * law.  Dissemination of this information or reproduction of
 * this material is strictly forbidden unless prior written
 * permission is obtained from Elasticsearch B.V.
 */

import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import {
  EuiPanel,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
  EuiButton,
  EuiSpacer,
  EuiIcon,
  EuiCallOut,
  EuiToolTip,
  EuiBadge,
} from '@elastic/eui'

import SetupEmailDevice from '@modules/mfa-enforcement/EnableMfaPage/SetupEmailDevice'
import { useGetSaasCurrentUserMfaDevicesQuery } from '@modules/cloud-lib/users/hooks/mfa'
import SetupAuthenticatorDevice from '@modules/mfa-enforcement/EnableMfaPage/SetupAuthenticatorDevice'
import { parseError, useEnrollWebAuthnMutation } from '@modules/cloud-lib/users/hooks/webauthn'
import type { SaasAuthMfaDeviceResponse } from '@modules/cloud-api/v1/types'

import RemoveMfa from './RemoveMfa'
import { WebAuthnSecondTourStep } from './WebAuthnTourSteps'

import type { ReactElement } from 'react'
import type { DeviceType } from './types'

const DeviceOption: React.FunctionComponent<{ type: DeviceType }> = ({ type }) => {
  const [activeModal, setActiveModal] = useState<{
    operation: 'setup' | 'remove'
    device?: SaasAuthMfaDeviceResponse
  } | null>(null)
  const { data } = useGetSaasCurrentUserMfaDevicesQuery()

  const activeDevices = data?.mfa_devices.filter(({ status }) => status === 'ACTIVE') ?? []
  const [activeDevice] = activeDevices.filter(({ device_type }) => device_type === type)

  const { isLoading: isEnrollingWebAuthn, enrollWebAuthn, error } = useEnrollWebAuthnMutation()
  const webAuthnErrorMessage = parseError(error)

  const canRemoveDevices = activeDevices.length > 1

  return (
    <Fragment>
      <EuiPanel paddingSize='l' hasBorder={true}>
        <EuiFlexGroup>
          <EuiFlexGroup direction='column' gutterSize='s'>
            <EuiFlexItem>
              <EuiFlexGroup justifyContent='spaceEvenly' alignItems='center' gutterSize='s'>
                <EuiFlexItem>
                  <h2>
                    <EuiText size='s'>
                      <strong>
                        {type === 'GOOGLE' && (
                          <FormattedMessage
                            id='mfa-management.authenticator.title'
                            defaultMessage='Authenticator'
                          />
                        )}
                        {type === 'EMAIL' && (
                          <FormattedMessage
                            id='mfa-management.email.title'
                            defaultMessage='Email'
                          />
                        )}
                        {type === 'WEBAUTHN' && (
                          <FormattedMessage
                            id='mfa-management.webauthn.title'
                            defaultMessage='Security key or biometrics'
                          />
                        )}
                        {type === 'SMS' && (
                          <EuiFlexGroup gutterSize='s'>
                            <FormattedMessage id='mfa-management.sms.title' defaultMessage='SMS' />

                            <EuiBadge color='hollow'>
                              <FormattedMessage
                                id='authenticator.sms.deprecated'
                                defaultMessage='Deprecated'
                              />
                            </EuiBadge>

                            <EuiToolTip
                              title={
                                <FormattedMessage
                                  id='mfa-management.sms.deprecated-tooltip-title'
                                  defaultMessage='SMS authentication is going away'
                                />
                              }
                              content={
                                <FormattedMessage
                                  id='mfa-management.sms.deprecated-tooltip-content'
                                  defaultMessage="To enhance our security measures, we'll be removing this option in an upcoming update. Set up an alternative authentication method to avoid any future disruptions."
                                />
                              }
                              position='right'
                            >
                              <EuiIcon type='questionInCircle' color='primary' aria-label='Help' />
                            </EuiToolTip>
                          </EuiFlexGroup>
                        )}
                      </strong>
                    </EuiText>
                  </h2>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>

            <EuiFlexItem>
              <EuiText size='s'>
                {type === 'GOOGLE' && (
                  <FormattedMessage
                    id='mfa-management.authenticator.description'
                    defaultMessage='Generate verification codes on your mobile device with your preferred security app'
                  />
                )}
                {type === 'EMAIL' && (
                  <FormattedMessage
                    id='mfa-management.email.description'
                    defaultMessage='Receive a verification code on your email'
                  />
                )}
                {type === 'WEBAUTHN' && (
                  <FormattedMessage
                    id='authenticator.webauthn.description'
                    defaultMessage='Authenticate using a hardware security key or biometrics such as face and fingerprint recognition'
                  />
                )}
                {type === 'SMS' && (
                  <FormattedMessage
                    id='authenticator.sms.description'
                    defaultMessage='Receive a verification code on mobile device via SMS'
                  />
                )}
              </EuiText>

              <EuiSpacer size='s' />

              {webAuthnErrorMessage && (
                <EuiCallOut
                  title={
                    <FormattedMessage
                      id='authenticator.webauthn.error'
                      defaultMessage='Could not enable the security key or biometrics method'
                    />
                  }
                  color='danger'
                >
                  {webAuthnErrorMessage}
                </EuiCallOut>
              )}
            </EuiFlexItem>

            <EuiFlexGroup>
              <EuiFlexItem grow={false}>
                {activeDevice !== undefined ? (
                  <RemoveDeviceTooltip canRemoveDevices={canRemoveDevices}>
                    <EuiButton
                      disabled={!canRemoveDevices}
                      size='s'
                      color='danger'
                      onClick={() => {
                        setActiveModal({
                          operation: 'remove',
                          device: activeDevice,
                        })
                      }}
                      data-test-id={`remove-button-${type}`}
                    >
                      <FormattedMessage id='mfa-management.remove' defaultMessage='Remove' />
                    </EuiButton>
                  </RemoveDeviceTooltip>
                ) : (
                  <WebAuthnSecondTourStep type={type}>
                    <EuiButton
                      isLoading={isEnrollingWebAuthn}
                      size='s'
                      onClick={() => {
                        if (type === 'WEBAUTHN') {
                          enrollWebAuthn()
                          return
                        }

                        setActiveModal({ operation: 'setup' })
                      }}
                      data-test-id={`setup-button-${type}`}
                    >
                      <FormattedMessage id='mfa-management.set-up' defaultMessage='Set up' />
                    </EuiButton>
                  </WebAuthnSecondTourStep>
                )}
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexGroup>

          {activeDevice !== undefined && (
            <EuiFlexItem grow={false}>
              <EuiIcon type='checkInCircleFilled' size='m' />
            </EuiFlexItem>
          )}
        </EuiFlexGroup>
      </EuiPanel>

      {activeModal?.operation === 'remove' && activeModal.device && (
        <RemoveMfa
          device={activeModal.device}
          activeDevices={activeDevices}
          closeModal={() => {
            setActiveModal(null)
          }}
        />
      )}

      {activeModal?.operation === 'setup' && type === 'GOOGLE' && (
        <SetupAuthenticatorDevice
          closeModal={() => {
            setActiveModal(null)
          }}
        />
      )}

      {activeModal?.operation === 'setup' && type === 'EMAIL' && (
        <SetupEmailDevice
          closeModal={() => {
            setActiveModal(null)
          }}
        />
      )}
    </Fragment>
  )
}

const RemoveDeviceTooltip: React.FunctionComponent<{
  children: ReactElement
  canRemoveDevices: boolean
}> = ({ children, canRemoveDevices }) => {
  if (canRemoveDevices) {
    return children
  }

  return (
    <EuiToolTip
      position='top'
      content={
        <FormattedMessage
          id='remove-mfa.no-available-mfa-devices-error'
          defaultMessage='You must have at least one security method enabled. To preserve the security of your account, you will be able to remove this security method only after setting up a different one.'
        />
      }
    >
      {children}
    </EuiToolTip>
  )
}

export default DeviceOption
